export const Types = {
    GET_CLIENTS: 'GET_CLIENTS',
    GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
    GET_CLIENTS_ERROR: 'GET_CLIENTS_ERROR',
    GET_CLIENT_BY_ID: 'GET_CLIENT_BY_ID',
    GET_CLIENT_BY_ID_SUCCESS: 'GET_CLIENT_BY_ID_SUCCESS',
    GET_CLIENT_BY_ID_ERROR: 'GET_CLIENT_BY_ID_ERROR',
    POST_CLIENT: 'POST_CLIENT',
    POST_CLIENT_SUCCESS: 'POST_CLIENT_SUCCESS',
    POST_CLIENT_ERROR: 'POST_CLIENT_ERROR',
    PUT_CLIENT: 'PUT_CLIENT',
    PUT_CLIENT_SUCCESS: 'PUT_CLIENT_SUCCESS',
    PUT_CLIENT_ERROR: 'PUT_CLIENT_ERROR',
    CLIENT_CLEAN: 'CLIENT_CLEAN',
    DELETE_CLIENT: 'DELETE_CLIENT',
    DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
    DELETE_CLIENT_ERROR: 'DELETE_CLIENT_ERROR',
  };
  
  const INIT_STATE = {
    data: [],
    client: null,
    loading: false,
    error: null,
    postSuccess: false,
    deleteSuccess: false,
  
    amount: 10,
  };
  
  export default function Client(state = INIT_STATE, action) {
    switch (action.type) {
      case Types.GET_CLIENTS:
        return {
          ...state,
          data: [],
          loading: true,
          error: null,
        };
      case Types.GET_CLIENTS_SUCCESS:
        return {
          ...state,
          data: action.payload.data || action.payload,
          amount: action.payload.total,
          loading: false,
          error: null,
        };
      case Types.GET_CLIENTS_ERROR:
        return {
          ...state,
          data: [],
          loading: false,
          error: action.payload,
        };
      case Types.GET_CLIENT_BY_ID:
        return {
          ...state,
          client: null,
          loading: true,
          error: null,
        };
      case Types.GET_CLIENT_BY_ID_SUCCESS:
        return {
          ...state,
          client: action.payload,
          loading: false,
          error: null,
        };
      case Types.GET_CLIENT_BY_ID_ERROR:
        return {
          ...state,
          client: null,
          loading: false,
          error: action.payload,
        };
      case Types.POST_CLIENT:
        return {
          ...state,
          postSuccess: false,
          loading: true,
          error: null,
        };
      case Types.POST_CLIENT_SUCCESS:
        return {
          ...state,
          postSuccess: true,
          loading: false,
          error: null,
        };
      case Types.POST_CLIENT_ERROR:
        return {
          ...state,
          postSuccess: false,
          loading: false,
          error: action.payload,
        };
      case Types.PUT_CLIENT:
        return {
          ...state,
          postSuccess: false,
          loading: true,
          error: null,
        };
      case Types.PUT_CLIENT_SUCCESS:
        return {
          ...state,
          postSuccess: true,
          loading: false,
          error: null,
        };
      case Types.PUT_CLIENT_ERROR:
        return {
          ...state,
          postSuccess: false,
          loading: false,
          error: action.payload,
        };
      case Types.CLIENT_CLEAN:
        return {
          ...state,
          postSuccess: false,
          deleteSuccess: false,
          loading: false,
          error: false,
        };
      case Types.DELETE_CLIENT:
        return {
          ...state,
          deleteSuccess: false,
          loading: true,
          error: false,
        };
      case Types.DELETE_CLIENT_SUCCESS:
        return {
          ...state,
          deleteSuccess: true,
          loading: false,
          error: false,
        };
      case Types.DELETE_CLIENT_ERROR:
        return {
          ...state,
          deleteSuccess: false,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  }
  
  export const Creators = {
    getClients: payload => ({
      type: Types.GET_CLIENTS,
      payload,
    }),
    getClientsSuccess: payload => ({
      type: Types.GET_CLIENTS_SUCCESS,
      payload,
    }),
    getClientsError: payload => ({
      type: Types.GET_CLIENTS_ERROR,
      payload,
    }),
    getClientById: payload => ({
      type: Types.GET_CLIENT_BY_ID,
      payload,
    }),
    getClientByIdSuccess: payload => ({
      type: Types.GET_CLIENT_BY_ID_SUCCESS,
      payload,
    }),
    getClientByIdError: payload => ({
      type: Types.GET_CLIENT_BY_ID_ERROR,
      payload,
    }),
    postClient: payload => ({
      type: Types.POST_CLIENT,
      payload,
    }),
    postClientSuccess: payload => ({
      type: Types.POST_CLIENT_SUCCESS,
      payload,
    }),
    postClientError: payload => ({
      type: Types.POST_CLIENT_ERROR,
      payload,
    }),
    putClient: payload => ({
      type: Types.PUT_CLIENT,
      payload,
    }),
    putClientSuccess: payload => ({
      type: Types.PUT_CLIENT_SUCCESS,
      payload,
    }),
    putClientError: payload => ({
      type: Types.PUT_CLIENT_ERROR,
      payload,
    }),
    clientClean: () => ({
      type: Types.CLIENT_CLEAN,
    }),
    deleteClient: payload => ({
      type: Types.DELETE_CLIENT,
      payload,
    }),
    deleteClientSuccess: payload => ({
      type: Types.DELETE_CLIENT_SUCCESS,
      payload,
    }),
    deleteClientError: payload => ({
      type: Types.DELETE_CLIENT_ERROR,
      payload,
    }),
  };
  