export const Types = {
  GET_USE_BIOGAS_LIST_REQUEST: 'GET_USE_BIOGAS_LIST_REQUEST',
  GET_USE_BIOGAS_LIST_SUCCESS: 'GET_USE_BIOGAS_LIST_SUCCESS',
  GET_USE_BIOGAS_LIST_FAIL: 'GET_USE_BIOGAS_LIST_FAIL',

  DELETE_USE_BIOGAS_REQUEST: 'DELETE_USE_BIOGAS_REQUEST',
  DELETE_USE_BIOGAS_SUCCESS: 'DELETE_USE_BIOGAS_SUCCESS',
  DELETE_USE_BIOGAS_FAIL: 'DELETE_USE_BIOGAS_FAIL',
  DELETE_USE_BIOGAS_CLEAN: 'DELETE_USE_BIOGAS_CLEAN',

  CREATE_USE_BIOGAS_REQUEST: 'CREATE_USE_BIOGAS_REQUEST',
  CREATE_USE_BIOGAS_SUCCESS: 'CREATE_USE_BIOGAS_SUCCESS',
  CREATE_USE_BIOGAS_FAIL: 'CREATE_USE_BIOGAS_FAIL',
  CREATE_USE_BIOGAS_CLEAN: 'CREATE_USE_BIOGAS_CLEAN',

  GET_ID_USE_BIOGAS_REQUEST: 'GET_ID_USE_BIOGAS_REQUEST',
  GET_ID_USE_BIOGAS_SUCCESS: 'GET_ID_USE_BIOGAS_SUCCESS',
  GET_ID_USE_BIOGAS_FAIL: 'GET_ID_USE_BIOGAS_FAIL',
  GET_ID_USE_BIOGAS_CLEAN: 'GET_ID_USE_BIOGAS_CLEAN',

  UPDATE_USE_BIOGAS_REQUEST: 'UPDATE_USE_BIOGAS_REQUEST',
  UPDATE_USE_BIOGAS_SUCCESS: 'UPDATE_USE_BIOGAS_SUCCESS',
  UPDATE_USE_BIOGAS_FAIL: 'UPDATE_USE_BIOGAS_FAIL',
  UPDATE_USE_BIOGAS_CLEAN: 'UPDATE_USE_BIOGAS_CLEAN',
};

const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
  amount: 10,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,

  createLoading: false,
  createError: false,
  createSuccess: false,

  idLoading: false,
  idError: false,
  idData: null,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,
};

export default function UseBiogas(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_USE_BIOGAS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_USE_BIOGAS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
      };
    case Types.GET_USE_BIOGAS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.DELETE_USE_BIOGAS_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_USE_BIOGAS_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_USE_BIOGAS_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_USE_BIOGAS_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.CREATE_USE_BIOGAS_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.CREATE_USE_BIOGAS_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.CREATE_USE_BIOGAS_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.CREATE_USE_BIOGAS_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.GET_ID_USE_BIOGAS_REQUEST:
      return {
        ...state,
        idLoading: true,
        idError: false,
      };
    case Types.GET_ID_USE_BIOGAS_SUCCESS:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: action.payload,
      };
    case Types.GET_ID_USE_BIOGAS_FAIL:
      return {
        ...state,
        idLoading: false,
        idError: true,
      };
    case Types.GET_ID_USE_BIOGAS_CLEAN:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: null,
      };

    case Types.UPDATE_USE_BIOGAS_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_USE_BIOGAS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_USE_BIOGAS_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_USE_BIOGAS_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getUseBiogasListRequest: payload => ({
    type: Types.GET_USE_BIOGAS_LIST_REQUEST,
    payload,
  }),
  getUseBiogasListSuccess: payload => ({
    type: Types.GET_USE_BIOGAS_LIST_SUCCESS,
    payload,
  }),
  getUseBiogasListFail: () => ({
    type: Types.GET_USE_BIOGAS_LIST_FAIL,
  }),

  // DELETE
  deleteUseBiogasRequest: payload => ({
    type: Types.DELETE_USE_BIOGAS_REQUEST,
    payload,
  }),
  deleteUseBiogasSuccess: () => ({
    type: Types.DELETE_USE_BIOGAS_SUCCESS,
  }),
  deleteUseBiogasFail: () => ({
    type: Types.DELETE_USE_BIOGAS_FAIL,
  }),
  deleteUseBiogasClean: () => ({
    type: Types.DELETE_USE_BIOGAS_CLEAN,
  }),

  // CREATE
  createUseBiogasRequest: payload => ({
    type: Types.CREATE_USE_BIOGAS_REQUEST,
    payload,
  }),
  createUseBiogasSuccess: payload => ({
    type: Types.CREATE_USE_BIOGAS_SUCCESS,
    payload,
  }),
  createUseBiogasFail: () => ({
    type: Types.CREATE_USE_BIOGAS_FAIL,
  }),
  createUseBiogasClean: () => ({
    type: Types.CREATE_USE_BIOGAS_CLEAN,
  }),

  // GET ID
  getIdUseBiogasRequest: payload => ({
    type: Types.GET_ID_USE_BIOGAS_REQUEST,
    payload,
  }),
  getIdUseBiogasSuccess: payload => ({
    type: Types.GET_ID_USE_BIOGAS_SUCCESS,
    payload,
  }),
  getIdUseBiogasFail: () => ({
    type: Types.GET_ID_USE_BIOGAS_FAIL,
  }),
  getIdUseBiogasClean: () => ({
    type: Types.GET_ID_USE_BIOGAS_CLEAN,
  }),

  // UPDATE
  updateUseBiogasRequest: payload => ({
    type: Types.UPDATE_USE_BIOGAS_REQUEST,
    payload,
  }),
  updateUseBiogasSuccess: payload => ({
    type: Types.UPDATE_USE_BIOGAS_SUCCESS,
    payload,
  }),
  updateUseBiogasFail: () => ({
    type: Types.UPDATE_USE_BIOGAS_FAIL,
  }),
  updateUseBiogasClean: () => ({
    type: Types.UPDATE_USE_BIOGAS_CLEAN,
  }),
};
