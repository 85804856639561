import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';

const Routes = ({ match, typeUser }) => (

  <Switch>
    {typeUser === 'admin' && (
      <>
        <Route
          path={`${match.url}/dashboard`}
          component={asyncComponent(() => import('./admin/home'))}
        />

        <Route
          path={`${match.url}/admin/list`}
          component={asyncComponent(() => import('./admin/Admins'))}
        />

        <Route
          path={`${match.url}/admin/new`}
          component={asyncComponent(() =>
            import('./admin/Admins/AdminRegister')
          )}
        />

        <Route
          path={`${match.url}/user/list`}
          component={asyncComponent(() => import('./admin/Users'))}
        />

        <Route
          path={`${match.url}/user/register`}
          component={asyncComponent(() => import('./admin/Users/UserRegister'))}
        />

        <Route
          path={`${match.url}/user/registrant/edit`}
          component={asyncComponent(() =>
            import('./admin/Users/RegistrantEdit')
          )}
        />

        <Route
          path={`${match.url}/production_detail/list`}
          component={asyncComponent(() => import('./admin/ProductionDetail'))}
        />

        <Route
          path={`${match.url}/production_detail/details`}
          component={asyncComponent(() =>
            import('./admin/ProductionDetail/Details')
          )}
        />

        <Route
          path={`${match.url}/ccee/import`}
          component={asyncComponent(() => import('./admin/CceeImport'))}
        />

        <Route
          path={`${match.url}/ccee/recs`}
          component={asyncComponent(() => import('./admin/RecImports'))}
        />

        <Route
          path={`${match.url}/ccee/month`}
          component={asyncComponent(() => import('./admin/MonthImports'))}
        />

        <Route
          path={`${match.url}/registrants/list`}
          component={asyncComponent(() => import('./admin/Registrants'))}
        />

        <Route
          path={`${match.url}/registrants/new`}
          component={asyncComponent(() =>
            import('./admin/Registrants/NewRegistrant')
          )}
        />

        <Route
          path={`${match.url}/enterprises/list`}
          component={asyncComponent(() => import('./admin/Enterprises'))}
        />

        <Route
          path={`${match.url}/enterprises/new`}
          component={asyncComponent(() =>
            import('./admin/Enterprises/NewEntreprise')
          )}
        />

        <Route
          path={`${match.url}/enterprises/details`}
          component={asyncComponent(() =>
            import('./admin/Enterprises/EnterpriseDetails')
          )}
        />

        <Route
          path={`${match.url}/rec/request`}
          component={asyncComponent(() => import('./admin/EmissionsRequest'))}
        />

        <Route
          path={`${match.url}/rec/approved`}
          component={asyncComponent(() => import('./admin/EmissionsApproved'))}
        />

        <Route
          path={`${match.url}/gasrec/retired`}
          component={asyncComponent(() => import('./admin/GasrecRetired'))}
        />

        <Route
          path={`${match.url}/nf/list`}
          component={asyncComponent(() => import('./admin/NfList'))}
        />

        <Route
          path={`${match.url}/nf/participant/register`}
          component={asyncComponent(() => import('./admin/NfListParticipant/NfRegister'))}
          exact={true}
        />

        <Route
          path={`${match.url}/nf/participant`}
          component={asyncComponent(() => import('./admin/NfListParticipant'))}
          exact={true}
        />

        <Route
          path={`${match.url}/nf/register`}
          component={asyncComponent(() => import('./admin/NfList/NfRegister'))}
        />

        <Route
          path={`${match.url}/report/financial`}
          component={asyncComponent(() => import('./admin/FinancialReport'))}
        />

        <Route
          path={`${match.url}/report/emission`}
          component={asyncComponent(() => import('./admin/EmissionReport'))}
        />

        <Route
          path={`${match.url}/rmd/list`}
          component={asyncComponent(() => import('./admin/RmdList'))}
        />

        <Route
          path={`${match.url}/cegcodes/list`}
          component={asyncComponent(() => import('./admin/CegCodesList'))}
        />

        <Route
          path={`${match.url}/cegcodes/new`}
          component={asyncComponent(() => import('./admin/CegCodesList/newCegCode'))}
        />

        <Route
          path={`${match.url}/participant/list`}
          component={asyncComponent(() => import('./admin/Participants'))}
        />

        <Route
          path={`${match.url}/participant/register`}
          component={asyncComponent(() =>
            import('./admin/Participants/ParticipantRegister')
          )}
        />

        <Route
          path={`${match.url}/issuer/list`}
          component={asyncComponent(() => import('./admin/Issuers'))}
        />

        <Route
          path={`${match.url}/issuer/register`}
          component={asyncComponent(() =>
            import('./admin/Issuers/IssuerRegister')
          )}
        />

        <Route
          path={`${match.url}/powerSupply/list`}
          component={asyncComponent(() => import('./admin/PowerSupply'))}
        />

        <Route
          path={`${match.url}/powerSupply/register`}
          component={asyncComponent(() =>
            import('./admin/PowerSupply/PowerSupplyRegister')
          )}
        />


        <Route
          path={`${match.url}/applicationBiogas/list`}
          component={asyncComponent(() => import('./admin/ApplicationBiogas'))}
        />

        <Route
          path={`${match.url}/applicationBiogas/register`}
          component={asyncComponent(() =>
            import('./admin/ApplicationBiogas/ApplicationBiogasRegister')
          )}
        />

        <Route
          path={`${match.url}/useBiogas/list`}
          component={asyncComponent(() => import('./admin/UseBiogas'))}
        />

        <Route
          path={`${match.url}/useBiogas/register`}
          component={asyncComponent(() =>
            import('./admin/UseBiogas/UseBiogasRegister')
          )}
        />

        <Route
          path={`${match.url}/substrate/list`}
          component={asyncComponent(() => import('./admin/Substrate'))}
        />

        <Route
          path={`${match.url}/substrate/register`}
          component={asyncComponent(() =>
            import('./admin/Substrate/SubstrateRegister')
          )}
        />

        <Route
          path={`${match.url}/generationTechnology/list`}
          component={asyncComponent(() =>
            import('./admin/GenerationTechnology')
          )}
        />

        <Route
          path={`${match.url}/imports_history`}
          component={asyncComponent(() => import('./admin/ImportsHistory'))}
        />

        <Route
          path={`${match.url}/generationTechnology/register`}
          component={asyncComponent(() =>
            import('./admin/GenerationTechnology/GenerationTechnologyRegister')
          )}
        />

        <Route
          path={`${match.url}/register_requests`}
          component={asyncComponent(() => import('./admin/RequestsRegister'))}
        />

        <Route
          path={`${match.url}/enterprises/request`}
          component={asyncComponent(() =>
            import('./admin/RequestsRegister/RequestDetails')
          )}
        />
      </>
    )}

    {/* rotas de usuarios */}

    {(typeUser === 'client' ||
      typeUser === 'client_admin' ||
      typeUser === 'client_financial') && (
        <>
          <Route
            path={`${match.url}/dashboard`}
            component={asyncComponent(() => import('./customer/home'))}
          />

          <Route
            path={`${match.url}/enterprises/list`}
            component={asyncComponent(() => import('./customer/Enterprises'))}
          />

          <Route
            path={`${match.url}/enterprises/new`}
            component={asyncComponent(() =>
              import('./customer/Enterprises/NewEntreprise')
            )}
          />

          <Route
            path={`${match.url}/enterprises/details`}
            component={asyncComponent(() =>
              import('./customer/Enterprises/EnterpriseDetails')
            )}
          />

          <Route
            path={`${match.url}/nf/list`}
            component={asyncComponent(() => import('./customer/NfList'))}
          />

          <Route
            path={`${match.url}/registrant`}
            component={asyncComponent(() =>
              import('./customer/RegistrantRegister')
            )}
          />

          <Route
            path={`${match.url}/users/list`}
            component={asyncComponent(() => import('./customer/Users'))}
          />

          <Route
            path={`${match.url}/users/register`}
            component={asyncComponent(() =>
              import('./customer/Users/UserRegister')
            )}
          />

          <Route
            path={`${match.url}/rec`}
            component={asyncComponent(() => import('./customer/RecEmission'))}
          />

          {/* <Route
            path={`${match.url}/register_enterprise`}
            component={asyncComponent(() =>
              import('./customer/EnterprisesRegister')
            )}
          /> */}

          <Route
            path={`${match.url}/production_detail/list`}
            component={asyncComponent(() => import('./customer/ProductionDetail'))}
          />

          <Route
            path={`${match.url}/production_detail/new`}
            component={asyncComponent(() =>
              import('./customer/ProductionDetail/NewProductionDetail')
            )}
          />

          <Route
            path={`${match.url}/production_detail/details`}
            component={asyncComponent(() =>
              import('./customer/ProductionDetail/Details')
            )}
          />
        </>
      )}


    {(typeUser === 'client_participant') && (
      <>

        <Route
          path={`${match.url}/dashboard`}
          component={asyncComponent(() => import('./participant/home'))}
        />

        <Route
          path={`${match.url}/client/list`}
          component={asyncComponent(() => import('./participant/client'))}
        />

        <Route
          path={`${match.url}/client/register`}
          component={asyncComponent(() =>
            import('./participant/client/ClientRegister')
          )}
        />


        <Route
          path={`${match.url}/nf/participant/list`}
          component={asyncComponent(() =>
            import('./participant/NfList')
          )}
        />

        <Route
          path={`${match.url}/saving/details`}
          component={asyncComponent(() =>
            import('./participant/recRetired')
          )}
        />

        <Route
          path={`${match.url}/saving/transfer`}
          component={asyncComponent(() =>
            import('./participant/recTransfer')
          )}
        />

        <Route
          path={`${match.url}/saving/account`}
          component={asyncComponent(() =>
            import('./participant/recSaving')
          )}
        />

        <Route
          path={`${match.url}/historic/traded`}
          component={asyncComponent(() =>
            import('./participant/historic/traded')
          )}
        />

        <Route
          path={`${match.url}/historic/received`}
          component={asyncComponent(() =>
            import('./participant/historic/received')
          )}
        />

      </>

    )}

    {(typeUser === 'client_issuer') && (
      <>
        <Route
          path={`${match.url}/dashboard`}
          component={asyncComponent(() =>
            import('./issuer/Home')
          )}
        />

        <Route
          path={`${match.url}/production_detail/list`}
          component={asyncComponent(() => import('./issuer/ProductionDetail'))}
        />

        <Route
          path={`${match.url}/production_detail/details`}
          component={asyncComponent(() =>
            import('./issuer/ProductionDetail/Details')
          )}
        />

        <Route
          path={`${match.url}/enterprises/list`}
          component={asyncComponent(() =>
            import('./issuer/Enterprises')
          )}
        />

        <Route
          path={`${match.url}/enterprises/new`}
          component={asyncComponent(() =>
            import('./issuer/Enterprises/NewEntreprise')
          )}
        />

        <Route
          path={`${match.url}/enterprises/details`}
          component={asyncComponent(() =>
            import('./issuer/Enterprises/EnterpriseDetails')
          )}
        />

        <Route
          path={`${match.url}/cegcodes/list`}
          component={asyncComponent(() => import('./issuer/CegCodesList'))}
        />

        <Route
          path={`${match.url}/cegcodes/new`}
          component={asyncComponent(() => import('./issuer/CegCodesList/newCegCode'))}
        />

        <Route
          path={`${match.url}/nf/list`}
          component={asyncComponent(() => import('./issuer/NfList'))}
        />

        <Route
          path={`${match.url}/nf/register`}
          component={asyncComponent(() => import('./issuer/NfList/NfRegister'))}
        />

        <Route
          path={`${match.url}/rec/request`}
          component={asyncComponent(() => import('./issuer/EmissionsRequest'))}
        />

        <Route
          path={`${match.url}/rec/approved`}
          component={asyncComponent(() => import('./issuer/EmissionsApproved'))}
        />

        <Route
          path={`${match.url}/registrants/list`}
          component={asyncComponent(() =>
            import('./issuer/Registrants')
          )}
        />

        <Route
          path={`${match.url}/registrants/new`}
          component={asyncComponent(() =>
            import('./issuer/Registrants/NewRegistrant')
          )}
        />

        <Route
          path={`${match.url}/participant/list`}
          component={asyncComponent(() =>
            import('./issuer/Participants')
          )}
        />

        <Route
          path={`${match.url}/participant/register`}
          component={asyncComponent(() =>
            import('./issuer/Participants/ParticipantRegister')
          )}
        />

        {/* <Route
          path={`${match.url}/register_requests`}
          component={asyncComponent(() =>
            import('./issuer/RequestsRegister')
          )}
        /> */}

        <Route
          path={`${match.url}/enterprises/request`}
          component={asyncComponent(() =>
            import('./issuer/RequestsRegister/RequestDetails')
          )}
        />
      </>

    )}

    {/* <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/> */}
  </Switch>


);

export default withRouter(Routes);
