export const Types = {
  GET_NOTA_PARTICIPANT: 'GET_NOTA_PARTICIPANT',
  GET_NOTA_PARTICIPANT_SUCCESS: 'GET_NOTA_PARTICIPANT_SUCCESS',
  GET_NOTA_PARTICIPANT_ERROR: 'GET_NOTA_PARTICIPANT_ERROR',

  GET_NOTA_PARTICIPANT_BY_ID: 'GET_NOTA_PARTICIPANT_BY_ID',
  GET_NOTA_PARTICIPANT_BY_ID_SUCCESS: 'GET_NOTA_PARTICIPANT_BY_ID_SUCCESS',
  GET_NOTA_PARTICIPANT_BY_ID_ERROR: 'GET_NOTA_PARTICIPANT_BY_ID_ERROR',
  GET_NOTA_PARTICIPANT_BY_ID_RESET: 'GET_NOTA_PARTICIPANT_BY_ID_RESET',

  PUT_NOTA_PARTICIPANT: 'PUT_NOTA_PARTICIPANT',
  PUT_NOTA_PARTICIPANT_SUCCESS: 'PUT_NOTA_PARTICIPANT_SUCCESS',
  PUT_NOTA_PARTICIPANT_ERROR: 'PUT_NOTA_PARTICIPANT_ERROR',
  PUT_NOTA_PARTICIPANT_RESET: 'PUT_NOTA_PARTICIPANT_RESET',

  PAID_NOTA_PARTICIPANT: 'PAID_NOTA_PARTICIPANT',
  PAID_NOTA_PARTICIPANT_SUCCESS: 'PAID_NOTA_PARTICIPANT_SUCCESS',
  PAID_NOTA_PARTICIPANT_ERROR: 'PAID_NOTA_PARTICIPANT_ERROR',
  PAID_NOTA_PARTICIPANT_RESET: 'PAID_NOTA_PARTICIPANT_RESET',

  RECEIPT_PARTICIPANT_UPLOAD: 'RECEIPT_PARTICIPANT_UPLOAD',
  RECEIPT_PARTICIPANT_UPLOAD_SUCCESS: 'RECEIPT_PARTICIPANT_UPLOAD_SUCCESS',
  RECEIPT_PARTICIPANT_UPLOAD_ERROR: 'RECEIPT_PARTICIPANT_UPLOAD_ERROR',
  RECEIPT_PARTICIPANT_UPLOAD_RESET: 'RECEIPT_PARTICIPANT_UPLOAD_RESET',
};

const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
  amount: 10,

  notaData: null,
  notaLoading: false,
  notaError: false,

  putLoading: false,
  putSuccess: false,
  putError: false,

  paidLoading: false,
  paidSuccess: false,
  paidError: false,

  receiptSuccess: false,
  receiptLoading: false,
  receiptError: false,
};

export default function NotaFiscalParticipant(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_NOTA_PARTICIPANT:
      return {
        ...state,
        loading: true,
        error: false,
        data: [],
      };
    case Types.GET_NOTA_PARTICIPANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
        amount: action.payload.total,
      };
    case Types.GET_NOTA_PARTICIPANT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      };
    case Types.GET_NOTA_PARTICIPANT_BY_ID:
      return {
        ...state,
        notaData: null,
        notaLoading: true,
        notaError: false,
      };
    case Types.GET_NOTA_PARTICIPANT_BY_ID_SUCCESS:
      return {
        ...state,
        notaData: action.payload,
        notaLoading: false,
        notaError: false,
      };
    case Types.GET_NOTA_PARTICIPANT_BY_ID_ERROR:
      return {
        ...state,
        notaData: null,
        notaLoading: false,
        notaError: action.payload,
      };
    case Types.GET_NOTA_PARTICIPANT_BY_ID_RESET:
      return {
        ...state,
        notaData: null,
        notaLoading: false,
        notaError: false,
      };
    case Types.PUT_NOTA_PARTICIPANT:
      return {
        ...state,
        putLoading: true,
        putSuccess: false,
        putError: false,
      };
    case Types.PUT_NOTA_PARTICIPANT_SUCCESS:
      return {
        ...state,
        putLoading: false,
        putSuccess: true,
        putError: false,
      };
    case Types.PUT_NOTA_PARTICIPANT_ERROR:
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: true,
      };
    case Types.PUT_NOTA_PARTICIPANT_RESET:
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: false,
      };
    case Types.PAID_NOTA_PARTICIPANT:
      return {
        ...state,
        paidLoading: true,
        paidSuccess: false,
        paidError: false,
      };
    case Types.PAID_NOTA_PARTICIPANT_SUCCESS:
      return {
        ...state,
        paidLoading: false,
        paidSuccess: true,
        paidError: false,
      };
    case Types.PAID_NOTA_PARTICIPANT_ERROR:
      return {
        ...state,
        paidLoading: false,
        paidSuccess: false,
        paidError: true,
      };
    case Types.PAID_NOTA_PARTICIPANT_RESET:
      return {
        ...state,
        paidLoading: false,
        paidSuccess: false,
        paidError: false,
      };
    case Types.RECEIPT_PARTICIPANT_UPLOAD:
      return {
        ...state,
        receiptSuccess: false,
        receiptLoading: true,
        receiptError: false,
      };
    case Types.RECEIPT_PARTICIPANT_UPLOAD_SUCCESS:
      return {
        ...state,
        receiptSuccess: true,
        receiptLoading: false,
        receiptError: false,
      };
    case Types.RECEIPT_PARTICIPANT_UPLOAD_ERROR:
      return {
        ...state,
        receiptSuccess: false,
        receiptLoading: false,
        receiptError: action.payload,
      };
    case Types.RECEIPT_PARTICIPANT_UPLOAD_RESET:
      return {
        ...state,
        receiptSuccess: false,
        receiptLoading: false,
        receiptError: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getNotasParticipant: payload => ({
    type: Types.GET_NOTA_PARTICIPANT,
    payload,
  }),
  getNotasParticipantSuccess: payload => ({
    type: Types.GET_NOTA_PARTICIPANT_SUCCESS,
    payload,
  }),
  getNotasParticipantError: () => ({
    type: Types.GET_NOTA_PARTICIPANT_ERROR,
  }),
  getNotaParticipantById: payload => ({
    type: Types.GET_NOTA_PARTICIPANT_BY_ID,
    payload,
  }),
  getNotaParticipantByIdSuccess: payload => ({
    type: Types.GET_NOTA_PARTICIPANT_BY_ID_SUCCESS,
    payload,
  }),
  getNotaParticipantByIdError: payload => ({
    type: Types.GET_NOTA_PARTICIPANT_BY_ID_ERROR,
    payload,
  }),
  getNotaParticipantByIdReset: () => ({
    type: Types.GET_NOTA_PARTICIPANT_BY_ID_RESET,
  }),
  putNotasParticipant: payload => ({
    type: Types.PUT_NOTA_PARTICIPANT,
    payload,
  }),
  putNotasParticipantSuccess: () => ({
    type: Types.PUT_NOTA_PARTICIPANT_SUCCESS,
  }),
  putNotasParticipantError: () => ({
    type: Types.PUT_NOTA_PARTICIPANT_ERROR,
  }),
  putNotasParticipantReset: () => ({
    type: Types.PUT_NOTA_PARTICIPANT_RESET,
  }),
  
  paidNotasParticipant: payload => ({
    type: Types.PAID_NOTA_PARTICIPANT,
    payload,
  }),
  paidNotasParticipantSuccess: () => ({
    type: Types.PAID_NOTA_PARTICIPANT_SUCCESS,
  }),
  paidNotasParticipantError: () => ({
    type: Types.PAID_NOTA_PARTICIPANT_ERROR,
  }),
  paidNotasParticipantReset: () => ({
    type: Types.PAID_NOTA_PARTICIPANT_RESET,
  }),


  receiptParticipantUpload: payload => ({
    type: Types.RECEIPT_PARTICIPANT_UPLOAD,
    payload,
  }),
  receiptParticipantUploadSuccess: () => ({
    type: Types.RECEIPT_PARTICIPANT_UPLOAD_SUCCESS,
  }),
  receiptParticipantUploadError: payload => ({
    type: Types.RECEIPT_PARTICIPANT_UPLOAD_ERROR,
    payload,
  }),
  receiptParticipantUploadReset: () => ({
    type: Types.RECEIPT_PARTICIPANT_UPLOAD_RESET,
  }),
};
