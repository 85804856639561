import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as UseBiogasActions,
  Types as UseBiogasTypes,
} from 'store/ducks/UseBiogas';
import api from '../../util/Api';

function* getUseBiogas({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/useBiogas`, {
      params: payload,
    });
    if (status === 200) {
      yield put(UseBiogasActions.getUseBiogasListSuccess(data));
    } else {
      yield put(UseBiogasActions.getUseBiogasListFail());
    }
  } catch (err) {
    yield put(UseBiogasActions.getUseBiogasListFail());
  }
}

function* deleteUseBiogas({ payload }) {
  try {
    const { status } = yield call(api.delete, `/useBiogas/${payload}`);
    if (status === 200) {
      yield put(UseBiogasActions.deleteUseBiogasSuccess());
    } else {
      yield put(UseBiogasActions.deleteUseBiogasFail());
    }
  } catch (err) {
    yield put(UseBiogasActions.deleteUseBiogasFail());
  }
}

function* createUseBiogas({ payload }) {
  try {
    const { status } = yield call(api.post, '/useBiogas', payload);
    if (status === 200) {
      yield put(UseBiogasActions.createUseBiogasSuccess());
    } else {
      yield put(UseBiogasActions.createUseBiogasFail());
    }
  } catch (err) {
    yield put(UseBiogasActions.createUseBiogasFail());
  }
}

function* getIdUseBiogas({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/useBiogas/${payload}`);
    if (status === 200) {
      yield put(UseBiogasActions.getIdUseBiogasSuccess(data));
    } else {
      yield put(UseBiogasActions.getIdUseBiogasFail());
    }
  } catch (err) {
    yield put(UseBiogasActions.getIdUseBiogasFail());
  }
}

function* updateUseBiogas({ payload }) {
  try {
    const { status } = yield call(
      api.put,
      `useBiogas/${payload.id}`,
      payload.data
    );
    if (status === 200) {
      yield put(UseBiogasActions.updateUseBiogasSuccess());
    } else {
      yield put(UseBiogasActions.updateUseBiogasFail());
    }
  } catch (err) {
    yield put(UseBiogasActions.updateUseBiogasFail());
  }
}

function* getUseBiogasWatcher() {
  yield takeLatest(UseBiogasTypes.GET_USE_BIOGAS_LIST_REQUEST, getUseBiogas);
}

function* deleteUseBiogasWatcher() {
  yield takeLatest(UseBiogasTypes.DELETE_USE_BIOGAS_REQUEST, deleteUseBiogas);
}

function* createUseBiogasWatcher() {
  yield takeLatest(UseBiogasTypes.CREATE_USE_BIOGAS_REQUEST, createUseBiogas);
}

function* getIdUseBiogasWatcher() {
  yield takeLatest(UseBiogasTypes.GET_ID_USE_BIOGAS_REQUEST, getIdUseBiogas);
}

function* updateUseBiogasWatcher() {
  yield takeLatest(UseBiogasTypes.UPDATE_USE_BIOGAS_REQUEST, updateUseBiogas);
}

export default function* rootSaga() {
  yield all([
    fork(getUseBiogasWatcher),
    fork(deleteUseBiogasWatcher),
    fork(createUseBiogasWatcher),
    fork(getIdUseBiogasWatcher),
    fork(updateUseBiogasWatcher),
  ]);
}
