import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import adminSagas from './Admin';
import userSagas from './User';
import registrantSagas from './Registrant';
import enterpriseSagas from './Enterprise';
import ibgeSagas from './Ibge';
import participantSaga from './Participant';
import sourceSaga from './Source';
import technologySaga from './Tecnology';
import recSaga from './Rec';
import notaFiscalSaga from './NotaFiscal';
import notaFiscalParticipant from './NotaFiscalParticipant';
import cepSaga from './Cep';
import cegSaga from './Ceg';
import fileSaga from './File';
import adminDashboard from './AdminDashboard';
import ClientDashboard from './ClientDashboard';
import participantDashboard from './ParticipantDashboard';
import ClientParticipant from './ClientParticipant';
import recRetired from './RecRetired';
import recTransfer from './RecTransfer';
import recSaving from './RecSaving';
import transationRetired from './TransationRetired';
import rdmsSaga from './Rdms';
import generationSaga from './Generation';
import recoverPasswordSaga from './RecoverPassword';
import consult from './Consult';
import issuerDashboard from './IssuerDashboard';
import issuer from './Issuer';
import productionDetail from './ProductionDetail';
import substrate from './Substrate';
import biogasUse from './UseBiogas';
import applicationBiogas from './ApplicationBiogas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    adminSagas(),
    userSagas(),
    registrantSagas(),
    enterpriseSagas(),
    ibgeSagas(),
    participantSaga(),
    sourceSaga(),
    technologySaga(),
    recSaga(),
    notaFiscalSaga(),
    notaFiscalParticipant(),
    cepSaga(),
    cegSaga(),
    fileSaga(),
    adminDashboard(),
    participantDashboard(),
    ClientDashboard(),
    ClientParticipant(),
    recRetired(),
    recTransfer(),
    recSaving(),
    transationRetired(),
    rdmsSaga(),
    generationSaga(),
    recoverPasswordSaga(),
    consult(),
    issuer(),
    issuerDashboard(),
    productionDetail(),
    substrate(),
    biogasUse(),
    applicationBiogas(),
  ]);
}
