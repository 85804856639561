export const Types = {
  GET_TOP_NUMBERS: 'GET_TOP_NUMBERS',
  GET_TOP_NUMBERS_SUCCESS: 'GET_TOP_NUMBERS_SUCCESS',
  GET_TOP_NUMBERS_ERROR: 'GET_TOP_NUMBERS_ERROR',

  GET_MWH_BY_YEAR: 'GET_MWH_BY_YEAR',
  GET_MWH_BY_YEAR_SUCCESS: 'GET_MWH_BY_YEAR_SUCCESS',
  GET_MWH_BY_YEAR_ERROR: 'GET_MWH_BY_YEAR_ERROR',

  GET_BUSINESS_BY_TECHNOLOGY: 'GET_BUSINESS_BY_TECHNOLOGY',
  GET_BUSINESS_BY_TECHNOLOGY_SUCCESS: 'GET_BUSINESS_BY_TECHNOLOGY_SUCCESS',
  GET_BUSINESS_BY_TECHNOLOGY_ERROR: 'GET_BUSINESS_BY_TECHNOLOGY_ERROR',

  GET_APPROVED_RECS_BY_EMISSION: 'GET_APPROVED_RECS_BY_EMISSION',
  GET_APPROVED_RECS_BY_EMISSION_SUCCESS:
    'GET_APPROVED_RECS_BY_EMISSION_SUCCESS',
  GET_APPROVED_RECS_BY_EMISSION_ERROR: 'GET_APPROVED_RECS_BY_EMISSION_ERROR',

  GET_APPROVED_RECS_BY_PRODUCTION: 'GET_APPROVED_RECS_BY_PRODUCTION',
  GET_APPROVED_RECS_BY_PRODUCTION_SUCCESS:
    'GET_APPROVED_RECS_BY_PRODUCTION_SUCCESS',
  GET_APPROVED_RECS_BY_PRODUCTION_ERROR:
    'GET_APPROVED_RECS_BY_PRODUCTION_ERROR',
};

const INIT_STATE = {
  topNumbers: {},
  topNumbersLoading: false,
  topNumbersError: null,

  mwhByYear: {},
  mwhByYearLoading: false,
  mwhByYearError: null,

  businessByTechnology: {},
  businessByTechnologyLoading: false,
  businessByTechnologyError: null,

  approvedRecsByEmission: {},
  approvedRecsByEmissionLoading: false,
  approvedRecsByEmissionError: null,

  approvedRecsByProduction: {},
  approvedRecsByProductionLoading: false,
  approvedRecsByProductionError: null,
};

export default function adminDashboard(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_TOP_NUMBERS:
      return {
        ...state,
        topNumbers: {},
        topNumbersLoading: true,
        topNumbersError: null,
      };
    case Types.GET_TOP_NUMBERS_SUCCESS:
      return {
        ...state,
        topNumbers: action.payload,
        topNumbersLoading: false,
        topNumbersError: null,
      };
    case Types.GET_TOP_NUMBERS_ERROR:
      return {
        ...state,
        topNumbers: {},
        topNumbersLoading: false,
        topNumbersError: action.payload,
      };

    case Types.GET_MWH_BY_YEAR:
      return {
        ...state,
        mwhByYear: {},
        mwhByYearLoading: true,
        mwhByYearError: null,
      };
    case Types.GET_MWH_BY_YEAR_SUCCESS:
      return {
        ...state,
        mwhByYear: action.payload,
        mwhByYearLoading: false,
        mwhByYearError: null,
      };
    case Types.GET_MWH_BY_YEAR_ERROR:
      return {
        ...state,
        mwhByYear: {},
        mwhByYearLoading: false,
        mwhByYearError: action.payload,
      };

    case Types.GET_BUSINESS_BY_TECHNOLOGY:
      return {
        ...state,
        businessByTechnology: {},
        businessByTechnologyLoading: true,
        businessByTechnologyError: null,
      };
    case Types.GET_BUSINESS_BY_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        businessByTechnology: action.payload,
        businessByTechnologyLoading: false,
        businessByTechnologyError: null,
      };
    case Types.GET_BUSINESS_BY_TECHNOLOGY_ERROR:
      return {
        ...state,
        businessByTechnology: {},
        businessByTechnologyLoading: false,
        businessByTechnologyError: action.payload,
      };

    case Types.GET_APPROVED_RECS_BY_EMISSION:
      return {
        ...state,
        approvedRecsByEmission: {},
        approvedRecsByEmissionLoading: true,
        approvedRecsByEmissionError: null,
      };
    case Types.GET_APPROVED_RECS_BY_EMISSION_SUCCESS:
      return {
        ...state,
        approvedRecsByEmission: action.payload,
        approvedRecsByEmissionLoading: false,
        approvedRecsByEmissionError: null,
      };
    case Types.GET_APPROVED_RECS_BY_EMISSION_ERROR:
      return {
        ...state,
        approvedRecsByEmission: {},
        approvedRecsByEmissionLoading: false,
        approvedRecsByEmissionError: action.payload,
      };

    case Types.GET_APPROVED_RECS_BY_PRODUCTION:
      return {
        ...state,
        approvedRecsByProduction: {},
        approvedRecsByProductionLoading: true,
        approvedRecsByProductionError: null,
      };
    case Types.GET_APPROVED_RECS_BY_PRODUCTION_SUCCESS:
      return {
        ...state,
        approvedRecsByProduction: action.payload,
        approvedRecsByProductionLoading: false,
        approvedRecsByProductionError: null,
      };
    case Types.GET_APPROVED_RECS_BY_PRODUCTION_ERROR:
      return {
        ...state,
        approvedRecsByProduction: {},
        approvedRecsByProductionLoading: false,
        approvedRecsByProductionError: action.payload,
      };

    default:
      return state;
  }
}

export const Creators = {
  getTopNumbers: () => ({
    type: Types.GET_TOP_NUMBERS,
  }),
  getTopNumbersSuccess: payload => ({
    type: Types.GET_TOP_NUMBERS_SUCCESS,
    payload,
  }),
  getTopNumbersError: payload => ({
    type: Types.GET_TOP_NUMBERS_ERROR,
    payload,
  }),

  getMwhByYear: () => ({
    type: Types.GET_MWH_BY_YEAR,
  }),
  getMwhByYearSuccess: payload => ({
    type: Types.GET_MWH_BY_YEAR_SUCCESS,
    payload,
  }),
  getMwhByYearError: payload => ({
    type: Types.GET_MWH_BY_YEAR_ERROR,
    payload,
  }),

  getBusinessByTechnology: () => ({
    type: Types.GET_BUSINESS_BY_TECHNOLOGY,
  }),
  getBusinessByTechnologySuccess: payload => ({
    type: Types.GET_BUSINESS_BY_TECHNOLOGY_SUCCESS,
    payload,
  }),
  getBusinessByTechnologyError: payload => ({
    type: Types.GET_BUSINESS_BY_TECHNOLOGY_ERROR,
    payload,
  }),

  getApprovedRecsByEmission: () => ({
    type: Types.GET_APPROVED_RECS_BY_EMISSION,
  }),
  getApprovedRecsByEmissionSuccess: payload => ({
    type: Types.GET_APPROVED_RECS_BY_EMISSION_SUCCESS,
    payload,
  }),
  getApprovedRecsByEmissionError: payload => ({
    type: Types.GET_APPROVED_RECS_BY_EMISSION_ERROR,
    payload,
  }),

  getApprovedRecsByProduction: () => ({
    type: Types.GET_APPROVED_RECS_BY_PRODUCTION,
  }),
  getApprovedRecsByProductionSuccess: payload => ({
    type: Types.GET_APPROVED_RECS_BY_PRODUCTION_SUCCESS,
    payload,
  }),
  getApprovedRecsByProductionError: payload => ({
    type: Types.GET_APPROVED_RECS_BY_PRODUCTION_ERROR,
    payload,
  }),
};
