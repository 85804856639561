import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as ConsultTypes,
  Creators as ConsultActions,
} from '../ducks/Consult';
import api from '../../util/Api';

function* getConsult({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/consult/${payload}`)
    if (status === 200) {
      yield put(ConsultActions.getConsultSuccess(data));
    }
  } catch (error) {
    yield put(
      ConsultActions.getConsultError('Error fetching statement')
    );
  }
}

function* getConsultWatcher() {
  yield takeLatest(ConsultTypes.GET_CONSULT, getConsult);
}

export default function* rootSaga() {
  yield all([
    fork(getConsultWatcher),
  ]);
}
