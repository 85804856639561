import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as NotaFiscalParticipantTypes,
  Creators as NotaFiscalParticipantActions,
} from '../ducks/NotaFiscalParticipant';
import api from '../../util/Api';

function* getNotasParticipant({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/invoice/participant', {
      params: payload,
    });
    if (status === 200) {
      yield put(NotaFiscalParticipantActions.getNotasParticipantSuccess(data));
    } else {
      yield put(NotaFiscalParticipantActions.getNotasParticipantError());
    }
  } catch (e) {
    yield put(NotaFiscalParticipantActions.getNotasParticipantError());
  }
}

function* getNotaParticipantById({ payload }) {
  const { id } = payload;
  try {
    const { status, data } = yield call(api.get, `/invoice/participant/${id}`);
    if (status === 200) {
      yield put(NotaFiscalParticipantActions.getNotaParticipantByIdSuccess(data));
    } else {
      yield put(NotaFiscalParticipantActions.getNotaParticipantByIdError());
    }
  } catch {
    yield put(NotaFiscalParticipantActions.getNotaParticipantByIdError());
  }
}

function* putNotasParticipant({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/invoice/participant/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (status === 200) {
      yield put(NotaFiscalParticipantActions.putNotasParticipantSuccess());
    } else {
      yield put(NotaFiscalParticipantActions.putNotasParticipantError());
    }
  } catch (e) {
    yield put(NotaFiscalParticipantActions.putNotasParticipantError());
  }
}

function* paidNotasParticipant({ payload }) {
  try {
    const { status } = yield call(api.post, `/invoice/participant/toPay/${payload}`);
    if (status === 200) {
      yield put(NotaFiscalParticipantActions.paidNotasParticipantSuccess());
    } else {
      yield put(NotaFiscalParticipantActions.paidNotasParticipantError());
    }
  } catch (e) {
    yield put(NotaFiscalParticipantActions.paidNotasParticipantError());
  }
}

function* receiptParticipantUpload({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.post, `/invoice/participant/receipt/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (status === 200) {
      yield put(NotaFiscalParticipantActions.receiptParticipantUploadSuccess());
    } else {
      yield put(NotaFiscalParticipantActions.receiptParticipantUploadError());
    }
  } catch (error) {
    yield put(NotaFiscalParticipantActions.receiptParticipantUploadError(error.response));
  }
}

function* getNotasWatcher() {
  yield takeLatest(NotaFiscalParticipantTypes.GET_NOTA_PARTICIPANT, getNotasParticipant);
}

function* getNotaByIdWatcher() {
  yield takeLatest(NotaFiscalParticipantTypes.GET_NOTA_PARTICIPANT_BY_ID, getNotaParticipantById);
}

function* putNotasWatcher() {
  yield takeLatest(NotaFiscalParticipantTypes.PUT_NOTA_PARTICIPANT, putNotasParticipant);
}

function* paidNotasWatcher() {
  yield takeLatest(NotaFiscalParticipantTypes.PAID_NOTA_PARTICIPANT, paidNotasParticipant);
}

function* receiptUploadWatcher() {
  yield takeLatest(NotaFiscalParticipantTypes.RECEIPT_PARTICIPANT_UPLOAD, receiptParticipantUpload);
}

export default function* rootSaga() {
  yield all([
    fork(getNotasWatcher),
    fork(getNotaByIdWatcher),
    fork(putNotasWatcher),
    fork(paidNotasWatcher),
    fork(receiptUploadWatcher),
  ]);
}
