import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SignIn from './SignIn';
import RecoverEmail from './RecoverEmail';
import RecoverPassword from './RecoverPassword';

function AuthRoute({ location }) {
  const typeUser = sessionStorage.getItem('TOTUM.TYPE_USER');
  const { initURL, authUser } = useSelector(({ auth }) => auth);

  let type = null;
  if (typeUser) {
    type = typeUser === 'admin' ? 'backoffice' : 'users';
  }

  const urlType = initURL.split('/');

  if (
    !type &&
    !authUser &&
    location.pathname !== '/signin' &&
    location.pathname !== '/recoverEmail' &&
    location.pathname !== '/recoverPassword'
  ) {
    return <Redirect to="/signin" />;
  }
  if (type !== null && urlType[1] !== type) {
    return <Redirect to={`/${type}/dashboard`} />;
  }
  if (location.pathname === '/') {
    if ((initURL === '' || initURL === '/' || initURL === '/signin') && type) {
      return <Redirect to={`/${type}/dashboard`} />;
    }
    return <Redirect to={initURL} />;
  }

  return (
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/recoverEmail" component={RecoverEmail} />
      <Route path="/recoverPassword" component={RecoverPassword} />
    </Switch>
  );
}

export default AuthRoute;
