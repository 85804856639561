import React from 'react'
import Logo from '../assets/images/totum_logo.png'
import Lottie from 'react-lottie';
import animationData from '../assets/images/loading.json';

export default function LoadingPage() {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center w-100">
      <div style={{width: 150, height:200}} className="d-flex flex-column justify-content-center align-items-center">
        <img className="imageLoading" src={Logo} style={{ width: 200, height: 130, marginBottom: 20 }} />
        <Lottie
          options={{ animationData, autoplay: true, loop: true }}
          isClickToPauseDisabled
        />
      </div>
    </div>
  )
}
