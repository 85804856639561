import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import { Creators as TransferRetiredActions, Types as TransferRetiredTypes } from '../ducks/TransationRetired';
import api from '../../util/Api';

function* getTransationRetired({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/transation/showGasrecs', {
      params: payload,
    });
    if (status === 200) {
      yield put(TransferRetiredActions.getTransationRetiredSuccess(data));
    } else {
      yield put(TransferRetiredActions.getTransationRetiredError());
    }
  } catch (err) {
    yield put(TransferRetiredActions.getTransationRetiredError());
  }
}

function* getGasrecRetired({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/transation/showGasrecsRetired', {
      params: payload,
    });
    if (status === 200) {
      yield put(TransferRetiredActions.getGasrecRetiredSuccess(data));
    } else {
      yield put(TransferRetiredActions.getGasrecRetiredError());
    }
  } catch (err) {
    yield put(TransferRetiredActions.getGasrecRetiredError());
  }
}


function* getPdfTransation({ payload }) {
  try {
    const response = yield call (api.get, `transation/getPdf/${payload}`,
      {
        params: payload,
      }
    );
    if (response.status === 200) {
      yield put(TransferRetiredActions.getPdfTransationSuccess());
    } else {
      yield put(TransferRetiredActions.getPdfTransationError());
    }
  } catch (err) {
    console.log(err);
  }
}

function* getTransationWatcher() {
  yield takeLatest(TransferRetiredTypes.GET_TRANSATION_RETIRED, getTransationRetired);
}

function* getGasrecWatcher() {
  yield takeLatest(TransferRetiredTypes.GET_GASREC_RETIRED, getGasrecRetired);
}

function* getPDFWatcher() {
  yield takeLatest(TransferRetiredTypes.GET_PDF_TRANSATION, getPdfTransation);
}


export default function* rootSaga() {
  yield all([
    fork(getGasrecWatcher),
    fork(getTransationWatcher),
    fork(getPDFWatcher),
  ]);
}
