import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as ClientTypes,
  Creators as ClientActions,
} from '../ducks/ClientParticipant';
import api from '../../util/Api';

function* getClients({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/clientParticipant/reedem', {
      params: payload,
    });
    if (status === 200) {
      yield put(ClientActions.getClientsSuccess(data));
    }
  } catch (error) {
    yield put(
      ClientActions.getClientsError('Error listing registrants')
    );
  }
}

function* getClientById({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/clientParticipant/reedem/${payload}`);
    if (status === 200) {
      yield put(ClientActions.getClientByIdSuccess(data));
    }
  } catch (error) {
    yield put(
      ClientActions.getClientByIdError('Error listing registrant')
    );
  }
}

function* postClient({ payload }) {
  try {
    const { status } = yield call(api.post, '/clientParticipant/reedem', payload);
    if (status === 200) {
      yield put(ClientActions.postClientSuccess());
    }
  } catch (error) {
    yield put(
      ClientActions.postClientError('Error registering registrant')
    );
  }
}

function* putClient({ payload }) {
  const { id } = payload;
  try {
    const { status } = yield call(api.put, `/clientParticipant/reedem/${id}`, payload.data);
    if (status === 200) {
      yield put(ClientActions.putClientSuccess());
    }
  } catch (error) {
    yield put(
      ClientActions.putClientError('Error editing registrant')
    );
  }
}


function* deleteClient({ payload }) {
  try {
    const { status } = yield call(api.delete, `/clientParticipant/reedem/${payload}`);
    if (status === 200) {
      yield put(ClientActions.deleteClientSuccess());
    }
  } catch (error) {
    yield put(
      ClientActions.deleteClientError('Error deleting registrant')
    );
  }
}

function* getClientsWatcher() {
  yield takeLatest(ClientTypes.GET_CLIENTS, getClients);
}
function* getClientByIdWatcher() {
  yield takeLatest(ClientTypes.GET_CLIENT_BY_ID, getClientById);
}
function* postClientWatcher() {
  yield takeLatest(ClientTypes.POST_CLIENT, postClient);
}
function* putClientWatcher() {
  yield takeLatest(ClientTypes.PUT_CLIENT, putClient);
}
function* deleteClientWatcher() {
  yield takeLatest(ClientTypes.DELETE_CLIENT, deleteClient);
}

export default function* rootSaga() {
  yield all([
    fork(getClientsWatcher),
    fork(postClientWatcher),
    fork(getClientByIdWatcher),
    fork(putClientWatcher),
    fork(deleteClientWatcher),
  ]);
}
