import React from 'react';
import ReactDOM from 'react-dom';

const rootEl = document.getElementById('app-site');

const render = () => {
  const MainApp = require('./MainApp').default;
  ReactDOM.render(<MainApp />, rootEl);
};

render();
