export const Types = {
  GET_SUBSTRACT_LIST_REQUEST: 'GET_SUBSTRACT_LIST_REQUEST',
  GET_SUBSTRACT_LIST_SUCCESS: 'GET_SUBSTRACT_LIST_SUCCESS',
  GET_SUBSTRACT_LIST_FAIL: 'GET_SUBSTRACT_LIST_FAIL',

  DELETE_SUBSTRACT_REQUEST: 'DELETE_SUBSTRACT_REQUEST',
  DELETE_SUBSTRACT_SUCCESS: 'DELETE_SUBSTRACT_SUCCESS',
  DELETE_SUBSTRACT_FAIL: 'DELETE_SUBSTRACT_FAIL',
  DELETE_SUBSTRACT_CLEAN: 'DELETE_SUBSTRACT_CLEAN',

  CREATE_SUBSTRACT_REQUEST: 'CREATE_SUBSTRACT_REQUEST',
  CREATE_SUBSTRACT_SUCCESS: 'CREATE_SUBSTRACT_SUCCESS',
  CREATE_SUBSTRACT_FAIL: 'CREATE_SUBSTRACT_FAIL',
  CREATE_SUBSTRACT_CLEAN: 'CREATE_SUBSTRACT_CLEAN',

  GET_ID_SUBSTRACT_REQUEST: 'GET_ID_SUBSTRACT_REQUEST',
  GET_ID_SUBSTRACT_SUCCESS: 'GET_ID_SUBSTRACT_SUCCESS',
  GET_ID_SUBSTRACT_FAIL: 'GET_ID_SUBSTRACT_FAIL',
  GET_ID_SUBSTRACT_CLEAN: 'GET_ID_SUBSTRACT_CLEAN',

  UPDATE_SUBSTRACT_REQUEST: 'UPDATE_SUBSTRACT_REQUEST',
  UPDATE_SUBSTRACT_SUCCESS: 'UPDATE_SUBSTRACT_SUCCESS',
  UPDATE_SUBSTRACT_FAIL: 'UPDATE_SUBSTRACT_FAIL',
  UPDATE_SUBSTRACT_CLEAN: 'UPDATE_SUBSTRACT_CLEAN',
};

const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
  amount: 10,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,

  createLoading: false,
  createError: false,
  createSuccess: false,

  idLoading: false,
  idError: false,
  idData: null,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,
};

export default function Substrate(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_SUBSTRACT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_SUBSTRACT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
      };
    case Types.GET_SUBSTRACT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.DELETE_SUBSTRACT_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_SUBSTRACT_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_SUBSTRACT_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_SUBSTRACT_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.CREATE_SUBSTRACT_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.CREATE_SUBSTRACT_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.CREATE_SUBSTRACT_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.CREATE_SUBSTRACT_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.GET_ID_SUBSTRACT_REQUEST:
      return {
        ...state,
        idLoading: true,
        idError: false,
      };
    case Types.GET_ID_SUBSTRACT_SUCCESS:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: action.payload,
      };
    case Types.GET_ID_SUBSTRACT_FAIL:
      return {
        ...state,
        idLoading: false,
        idError: true,
      };
    case Types.GET_ID_SUBSTRACT_CLEAN:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: null,
      };

    case Types.UPDATE_SUBSTRACT_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_SUBSTRACT_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_SUBSTRACT_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_SUBSTRACT_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET
  getSubstrateListRequest: payload => ({
    type: Types.GET_SUBSTRACT_LIST_REQUEST,
    payload,
  }),
  getSubstrateListSuccess: payload => ({
    type: Types.GET_SUBSTRACT_LIST_SUCCESS,
    payload,
  }),
  getSubstrateListFail: () => ({
    type: Types.GET_SUBSTRACT_LIST_FAIL,
  }),

  // DELETE
  deleteSubstrateRequest: payload => ({
    type: Types.DELETE_SUBSTRACT_REQUEST,
    payload,
  }),
  deleteSubstrateSuccess: () => ({
    type: Types.DELETE_SUBSTRACT_SUCCESS,
  }),
  deleteSubstrateFail: () => ({
    type: Types.DELETE_SUBSTRACT_FAIL,
  }),
  deleteSubstrateClean: () => ({
    type: Types.DELETE_SUBSTRACT_CLEAN,
  }),

  // CREATE
  createSubstrateRequest: payload => ({
    type: Types.CREATE_SUBSTRACT_REQUEST,
    payload,
  }),
  createSubstrateSuccess: payload => ({
    type: Types.CREATE_SUBSTRACT_SUCCESS,
    payload,
  }),
  createSubstrateFail: () => ({
    type: Types.CREATE_SUBSTRACT_FAIL,
  }),
  createSubstrateClean: () => ({
    type: Types.CREATE_SUBSTRACT_CLEAN,
  }),

  // GET ID
  getIdSubstrateRequest: payload => ({
    type: Types.GET_ID_SUBSTRACT_REQUEST,
    payload,
  }),
  getIdSubstrateSuccess: payload => ({
    type: Types.GET_ID_SUBSTRACT_SUCCESS,
    payload,
  }),
  getIdSubstrateFail: () => ({
    type: Types.GET_ID_SUBSTRACT_FAIL,
  }),
  getIdSubstrateClean: () => ({
    type: Types.GET_ID_SUBSTRACT_CLEAN,
  }),

  // UPDATE
  updateSubstrateRequest: payload => ({
    type: Types.UPDATE_SUBSTRACT_REQUEST,
    payload,
  }),
  updateSubstrateSuccess: payload => ({
    type: Types.UPDATE_SUBSTRACT_SUCCESS,
    payload,
  }),
  updateSubstrateFail: () => ({
    type: Types.UPDATE_SUBSTRACT_FAIL,
  }),
  updateSubstrateClean: () => ({
    type: Types.UPDATE_SUBSTRACT_CLEAN,
  }),
};
