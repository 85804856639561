// import indigo from '@material-ui/core/colors/indigo';
import lightGreen from '@material-ui/core/colors/lightGreen';
import pink from '@material-ui/core/colors/pink';

export default {
  palette: {
    primary: {
      light: lightGreen[300],
      main: lightGreen[800],
      dark: lightGreen[500],
      contrastText: '#fff'
    },
    secondary: {
      light: pink[300],
      main: pink['A200'],
      dark: pink[700],
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
};
