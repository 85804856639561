import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as GasrecSavingTypes,
  Creators as RecSavingActions,
} from '../ducks/RecSaving';
import api from '../../util/Api';

function* getGasrecSavings({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/saving', {
      params: payload,
    });
    if (status === 200) {
      yield put(RecSavingActions.getGasrecSavingSuccess(data));
    }
  } catch (error) {
    yield put(
      RecSavingActions.getGasrecSavingError('Error listing Gasrecs')
    );
  }
}


function* postGasrecSaving({ payload }) {
  try {
      const { status } = yield call(api.post, '/saving', payload);
      if (status === 200) {
        yield put(RecSavingActions.postGasrecSavingSuccess());
      }
    } catch (error) {
      yield put(RecSavingActions.postGasrecSavingError(error?.response?.data));
    }
  }

  function* putGasrecSaving({ payload }) {
    const { id } = payload;

    try {
      const { status } = yield call(api.put, `/saving/${id}`, payload);
      if (status === 200) {
        yield put(RecSavingActions.putGasrecSavingSuccess());
      }
    } catch (error) {
      yield put(RecSavingActions.putGasrecSavingError(error?.response?.data));
    }
  }


function* getGasrecsWatcher() {
  yield takeLatest(GasrecSavingTypes. GET_GASRECS_SAVINGS, getGasrecSavings);
}

function* postGasrecWatcher() {
  yield takeLatest(GasrecSavingTypes.POST_GASRECS_SAVINGS, postGasrecSaving);
}

function* putGasrecWatcher() {
  yield takeLatest(GasrecSavingTypes.PUT_GASRECS_SAVINGS, putGasrecSaving);
}

// function* getGasrecByIdWatcher() {
//   yield takeLatest(GasrecSavingTypes.GET_GASREC_PARTICIPANT_BY_ID, getGasrecSavingById);
// }

export default function* rootSaga() {
  yield all([
    fork(getGasrecsWatcher),
    fork(postGasrecWatcher),
    fork(putGasrecWatcher),
    // fork(getGasrecByIdWatcher),
  ]);
}
