import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from '../../components/Navigation';

const SideBarContent = ({ typeUser }) => {
  const type = typeUser === 'admin' ? 'backoffice' : 'users';
  const navigationMenusAdmin = [
    {
      name: ' ',
      type: 'section',
      children: [
        {
          name: 'sidebar.dashboard',
          icon: 'view-dashboard',
          type: 'item',
          link: `/${type}/dashboard`,
        },
        {
          name: 'sidebar.admins',
          icon: 'account',
          type: 'item',
          link: `/${type}/admin/list`,
        },
        {
          name: 'sidebar.users',
          icon: 'account-o',
          type: 'item',
          link: `/${type}/user/list`,
        },
        {
          name: 'Validate Production Data',
          icon: 'input-composite',
          type: 'item',
          link: `/${type}/production_detail/list`,
        },
        {
          name: 'sidebar.ccee',
          icon: 'upload',
          type: 'collapse',
          children: [
            {
              name: 'sidebar.ccee.import',
              type: 'item',
              link: `/${type}/ccee/import`,
            },
            {
              name: 'sidebar.ccee.recs',
              type: 'item',
              link: `/${type}/ccee/recs`,
            },
            {
              name: 'sidebar.ccee.permonth',
              type: 'item',
              link: `/${type}/ccee/month`,
            },
          ],
        },

        {
          name: 'Applications',
          icon: 'leak',
          type: 'collapse',
          children: [
            {
              name: 'Substrate',
              type: 'item',
              link: `/${type}/substrate/list`,
            },
            {
              name: 'Application Biogas',
              type: 'item',
              link: `/${type}/applicationBiogas/list`,
            },
            {
              name: 'Use Biogas',
              type: 'item',
              link: `/${type}/useBiogas/list`,
            },
          ],
        },

        {
          name: 'sidebar.reports',
          icon: 'file',
          type: 'collapse',
          children: [
            {
              name: 'sidebar.reports.financial',
              type: 'item',
              link: `/${type}/report/financial`,
            },
            {
              name: 'sidebar.reports.emission',
              type: 'item',
              link: `/${type}/report/emission`,
            },
          ],
        },
        {
          name: 'sidebar.emissionsRequests',
          icon: 'thumb-up-down',
          type: 'item',
          link: `/${type}/rec/request`,
        },
        {
          name: 'sidebar.emissionsApproved',
          icon: 'thumb-up',
          type: 'item',
          link: `/${type}/rec/approved`,
        },
        {
          name: 'sidebar.retiredCertificates',
          icon: 'swap-vertical',
          type: 'item',
          link: `/${type}/gasrec/retired`,
        },
        {
          name: 'sidebar.financial',
          icon: 'money',
          type: 'item',
          link: `/${type}/nf/list`,
        },
        {
          name: 'sidebar.financialParticipant',
          icon: 'balance',
          type: 'item',
          link: `/${type}/nf/participant`,
        },
        {
          name: 'sidebar.mixResidual',
          icon: 'assignment-o',
          type: 'item',
          link: `/${type}/rmd/list`,
        },
        {
          name: 'sidebar.cegCodes',
          icon: 'more',
          type: 'item',
          link: `/${type}/cegcodes/list`,
        },
        {
          name: 'sidebar.enterprises',
          icon: 'city',
          type: 'item',
          link: `/${type}/enterprises/list`,
        },
        {
          name: 'sidebar.registrants',
          icon: 'accounts',
          type: 'item',
          link: `/${type}/registrants/list`,
        },
        {
          name: 'sidebar.participants',
          icon: 'accounts-outline',
          type: 'item',
          link: `/${type}/participant/list`,
        },
        {
          name: 'Issuers',
          icon: 'accounts-list-alt',
          type: 'item',
          link: `/${type}/issuer/list`,
        },
        {
          name: 'sidebar.energyFont',
          icon: 'input-power',
          type: 'item',
          link: `/${type}/powerSupply/list`,
        },
        {
          name: 'Biogas Applications',
          icon: 'memory',
          type: 'item',
          link: `/${type}/generationTechnology/list`,
        },
        {
          name: 'sidebar.historyOfImports',
          icon: 'time-restore',
          type: 'item',
          link: `/${type}/imports_history`,
        },
        {
          name: 'sidebar.registerRequests',
          icon: 'assignment',
          type: 'item',
          link: `/${type}/register_requests`,
        },
      ],
    },
  ];

  const navigationMenusParticipant = [
    {
      name: ' ',
      type: 'section',
      children: [
        {
          name: 'sidebar.dashboard',
          icon: 'view-dashboard',
          type: 'item',
          link: `/${type}/dashboard`,
        },
        {
          name: 'Beneficiary',
          icon: 'account-box',
          type: 'item',
          link: `/${type}/client/list`,
        },
        {
          name: "Transactions",
          icon: 'money',
          type: 'collapse',
          children: [
            {
              name: 'Retire',
              type: 'item',
              link: `/${type}/saving/details`,
            },
            {
              name: 'Transfer',
              type: 'item',
              link: `/${type}/saving/transfer`,
            },
            {
              name: 'Reservation',
              type: 'item',
              link: `/${type}/saving/account`,
            },

          ],
        },
        {
          name: "Historic",
          icon: 'swap',
          type: 'collapse',
          children: [
            {
              name: 'Retirees',
              type: 'item',
              link: `/${type}/historic/traded`,
            },
            {
              name: 'Received',
              type: 'item',
              link: `/${type}/historic/received`,
            },

          ],
        },
        {
          name: 'sidebar.financial',
          icon: 'money',
          type: 'item',
          link: `/${type}/nf/participant/list`,
        },
      ],
    },
  ];

  const navigationMenusIssuer = [
    {
      name: ' ',
      type: 'section',
      children: [
        {
          name: 'sidebar.dashboard',
          icon: 'view-dashboard',
          type: 'item',
          link: `/${type}/dashboard`,
        },
        {
          name: 'Validate Production Data',
          icon: 'input-composite',
          type: 'item',
          link: `/${type}/production_detail/list`,
        },
        {
          name: 'sidebar.enterprises',
          icon: 'city',
          type: 'item',
          link: `/${type}/enterprises/list`,
        },
        {
          name: 'sidebar.cegCodes',
          icon: 'more',
          type: 'item',
          link: `/${type}/cegcodes/list`,
        },
        {
          name: 'sidebar.financial',
          icon: 'money',
          type: 'item',
          link: `/${type}/nf/list`,
        },
        {
          name: 'sidebar.emissionsRequests',
          icon: 'thumb-up-down',
          type: 'item',
          link: `/${type}/rec/request`,
        },
        {
          name: 'sidebar.emissionsApproved',
          icon: 'thumb-up',
          type: 'item',
          link: `/${type}/rec/approved`,
        },
        {
          name: 'sidebar.registrants',
          icon: 'accounts',
          type: 'item',
          link: `/${type}/registrants/list`,
        },
        {
          name: 'sidebar.participants',
          icon: 'accounts-outline',
          type: 'item',
          link: `/${type}/participant/list`,
        },
      ],
    },
  ];


  const navigationMenusCustomer = [
    {
      name: ' ',
      type: 'section',
      children: [
        {
          name: 'sidebar.dashboard',
          icon: 'view-dashboard',
          type: 'item',
          link: `/${type}/dashboard`,
        },
        {
          name: 'Production Data',
          icon: 'input-composite',
          type: 'item',
          link: `/${type}/production_detail/list`,
        },
        {
          name: 'sidebar.enterprises',
          icon: 'city',
          type: 'item',
          link: `/${type}/enterprises/list`,
        },
        {
          name: 'sidebar.financial',
          icon: 'money',
          type: 'item',
          link: `/${type}/nf/list`,
        },
        {
          name: 'sidebar.registeredData',
          icon: 'account-box',
          type: 'item',
          link: `/${type}/registrant`,
        },
        {
          name: 'sidebar.users',
          icon: 'account-o',
          type: 'item',
          link: `/${type}/users/list`,
        },
        {
          name: 'sidebar.emissionRecs',
          icon: 'file-text',
          type: 'item',
          link: `/${type}/rec`,
        },
      ],
    },
  ];

  let typeSidebar = ''

  if (typeUser === 'admin') {
    typeSidebar = navigationMenusAdmin
  }
  else if (typeUser === 'client_participant') {
    typeSidebar = navigationMenusParticipant
  }
  else if (typeUser === 'client_issuer') {
    typeSidebar = navigationMenusIssuer
  }
  else {
    typeSidebar = navigationMenusCustomer
  }

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation
        menuItems={
          typeSidebar
        }
      />
    </CustomScrollbars>
  );
};

export default SideBarContent;
