import React from 'react';

const Footer = () => (
  <footer className="app-footer">
    <span className="d-inline-block">
      Copyright &copy; 2020 - Instituto Totum - Todos os Direitos Reservados
    </span>
  </footer>
);
export default Footer;
