import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as EnterpriseTypes,
  Creators as EnterpriseActions,
} from '../ducks/Enterprise';
import api from '../../util/Api';

function* getEnterprises({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/businesses', {
      params: payload,
    });
    if (status === 200) {
      yield put(EnterpriseActions.getEnterprisesSuccess(data));
    }
  } catch (error) {
    yield put(
      EnterpriseActions.getEnterprisesError('Error listing projects')
    );
  }
}

function* postEnterprise({ payload }) {

  try {
    const { status } = yield call(api.post, '/businesses/create', payload, {
      headers: {
        ...(payload instanceof FormData
          ? { 'Content-Type': 'multipart/form-data' }
          : {}),
      },
    });
    if (status === 200) {
      yield put(EnterpriseActions.postEnterpriseSuccess());
    }
  } catch (error) {
    const erro = yield error
    yield put(EnterpriseActions.postEnterpriseError(error?.response?.data || error?.message || erro));
  }
}

function* getEnterpriseById({ payload }) {
  const { id, params, edit } = payload;
  try {
    const { status, data } = yield call(api.get, `/businesses/${id}`, {
      params,
    });
    if (status === 200) {
      if (edit && data?.approved !== 'APPROVED') {
        yield put(
          EnterpriseActions.getEnterpriseByIdError(
            'development not found'
          )
        );
      }
      yield put(EnterpriseActions.getEnterpriseByIdSuccess(data));
    }
  } catch (error) {
    yield put(
      EnterpriseActions.getEnterpriseByIdError('development not found')
    );
  }
}

function* putEnterprise({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/businesses/${id}`, data, {
      headers: {
        ...(data instanceof FormData
          ? { 'Content-Type': 'multipart/form-data' }
          : {}),
      },
    });
    if (status === 200) {
      yield put(EnterpriseActions.putEnterpriseSuccess());
    }
  } catch (error) {
    yield put(
      EnterpriseActions.putEnterpriseError('Error editing unit')
    );
  }
}

function* deleteEnterprise({ payload }) {
  try {
    const { status } = yield call(api.delete, `/businesses/${payload}`);
    if (status === 200) {
      yield put(EnterpriseActions.deleteEnterpriseSuccess());
    }
  } catch (error) {
    yield put(
      EnterpriseActions.deleteEnterpriseError('Error deleting unit')
    );
  }
}

function* approveEnterprise({ payload }) {
  try {
    const { status } = yield call(api.post, `/businesses/approve/${payload}`);
    if (status === 200) {
      yield put(EnterpriseActions.approveEnterpriseSuccess());
    }
  } catch (error) {
    yield put(
      EnterpriseActions.approveEnterpriseError(error?.response?.data?.message)
    );
  }
}

function* refuseEnterprise({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.post, `/businesses/refuse/${id}`, data);
    if (status === 200) {
      yield put(EnterpriseActions.refuseEnterpriseSuccess());
    }
  } catch (error) {
    yield put(EnterpriseActions.refuseEnterpriseError());
  }
}

function* getEnterprisesWatcher() {
  yield takeLatest(EnterpriseTypes.GET_ENTERPRISES, getEnterprises);
}

function* postEnterpriseWatcher() {
  yield takeLatest(EnterpriseTypes.POST_ENTERPRISE, postEnterprise);
}

function* getEnterpriseByIdWatcher() {
  yield takeLatest(EnterpriseTypes.GET_ENTERPRISE_BY_ID, getEnterpriseById);
}

function* putEnterpriseWatcher() {
  yield takeLatest(EnterpriseTypes.PUT_ENTERPRISE, putEnterprise);
}

function* deleteEnterpriseWatcher() {
  yield takeLatest(EnterpriseTypes.DELETE_ENTERPRISE, deleteEnterprise);
}

function* approveEnterpriseWatcher() {
  yield takeLatest(EnterpriseTypes.APPROVE_ENTERPRISE, approveEnterprise);
}

function* refuseEnterpriseWatcher() {
  yield takeLatest(EnterpriseTypes.REFUSE_ENTERPRISE, refuseEnterprise);
}

export default function* rootSaga() {
  yield all([
    fork(getEnterprisesWatcher),
    fork(postEnterpriseWatcher),
    fork(getEnterpriseByIdWatcher),
    fork(putEnterpriseWatcher),
    fork(deleteEnterpriseWatcher),
    fork(approveEnterpriseWatcher),
    fork(refuseEnterpriseWatcher),
  ]);
}
