import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Creators as AuthActions, Types as AuthTypes } from 'store/ducks/Auth';
import api from '../../util/Api';

const saveToken = (accessToken, refreshToken) => {
  sessionStorage.setItem('TOTUM.ACCESS_TOKEN', accessToken);
  localStorage.setItem('TOTUM.REFRESH_TOKEN', refreshToken);
};
const saveTypeUser = typeUser => {
  sessionStorage.setItem('TOTUM.TYPE_USER', typeUser);
};

// eslint-disable-next-line consistent-return
function* login({ payload }) {
  try {
    const { status, data } = yield call(api.post, '/auth/login', payload);
    if (status === 200) {
      if (data.roles.includes('service')) {
        return yield put(
          AuthActions.loginError({
            message:
              'Invalid login, services credentials cannot access the system',
          })
        );
      }
      saveToken(data.token, data.refreshToken);
      saveTypeUser(data.roles);
      yield put(AuthActions.setTypeUser(data.roles[0]));
      yield put(AuthActions.loginSuccess(data.user));
    }
  } catch (error) {
    yield put(AuthActions.loginError(error.response.data));
  }
}

function* loginWatcher() {
  yield takeLatest(AuthTypes.LOGIN, login);
}

export default function* rootSaga() {
  yield all([fork(loginWatcher)]);
}
