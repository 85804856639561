import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ConsultActions } from '../store/ducks/Consult'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from "moment";
import { useParams, useHistory } from 'react-router-dom'

function formatNumber(value) {
    const result = (value * 0.293).toString()
    const hasIndex = result.includes(".")
    let formatValue = null

    if (hasIndex) {
        const index = result.indexOf(".")
        const format = result.slice(0, (index + 3))
        const formatNumber = format.slice(index + 1).length
        formatNumber === 1 ? formatValue = format.concat("0") : formatValue = format
    }
    else {
        formatValue = result
    }

    return formatValue
}


function Declaration() {
    const history = useHistory();
    const { hash } = useParams()
    const dispatch = useDispatch()
    const [code, setCode] = useState("")
    const [gasrec, setGasrec] = useState("")
    const { data, loading, error } = useSelector(({ consult }) => consult)

    useEffect(() => {
        dispatch(ConsultActions.getConsult(hash));
    }, [hash])

    useEffect(() => {
        setGasrec(data);
        if (data) {
            setCode(data.declaration_final.slice(0, 4))
        }
    }, [data])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                history.push('/users/historic/traded');
            }, 2000);
        }
    }, [error])

    const useStyles = makeStyles({

        table: {
            width: "795px",
            border: "1px solid"
        },

        th: {
            fontWeight: "500",
            backgroundColor: "rgba(141, 179, 139, 0.2)",
        },

        title: {
            fontWeight: "500",
        },

        div: {
            display: "block"
        },

        container: {
            overflow: "auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgb(255, 255, 255)"
        }
    });

    let BeneficiarioRows = null
    let DispositivoRows = null
    let DeclaracaoRows = null

    if (gasrec) {

        BeneficiarioRows = [
            { name: 'Recipient', data: gasrec.client.name },
            { name: 'Beneficiary Location', data: gasrec.client.localization },
            { name: "Beneficiary's Country", data: gasrec.client.country },
            { name: 'Redemption Date', data: moment(gasrec.created_at).format('DD/MM/YYYY') },
            { name: 'Year of Consumption Report', data: moment(gasrec.date_initial).format('DD/MM/YYYY') + " | " + moment(gasrec.date_final).format('DD/MM/YYYY') },
            { name: 'Redeemed I-REC(G)/GAS-REC Number', data: gasrec.volume + " MMBTU" },
            { name: 'Equivalent in MWh', data: formatNumber(gasrec.volume) }
        ]

        DispositivoRows = [
            { name: 'Device', data: gasrec.business.social_name + " | " + `I-REC(G)/GAS-REC-${code}` },
            { name: 'Country of origin', data: gasrec.business.country },
            { name: 'Substrate', data: gasrec.business.substrate },
            { name: 'Biogas application', data: gasrec.business.biogas_aplication },
            { name: 'Use of Biogas or Biomethane', data: gasrec.business.biogas_utility },
            { name: 'Detailing', data: gasrec.business.biogas_details },
            { name: 'Avoided Methane', data: gasrec.business.avoided_methane || "Not applicable" },
            { name: 'Avoided Methane Reference', data: gasrec.business.reference_methane || "Not applicable" },
            { name: 'Emission Reduction', data: gasrec.business.emission_reduction || "Not applicable" },
            { name: 'Emission Reduction Reference', data: gasrec.business.reference_reduction || "Not applicable" },
            { name: 'Commissioning Date', data: moment(gasrec.business.comercial_operation_at).format('DD/MM/YYYY') },
            { name: 'Emission Factor Considering Life Cycle (tCO2eq/MMBTU)*', data: gasrec.business.carbon || "Not applicable" },
            { name: 'Reference', data: gasrec.business.carbon_reference || "Not applicable" },
            { name: 'Carbon (tCO2/Million BTU) - Biogas/Biomethane Burning', data: gasrec.business.burned_carbon },
            { name: 'Reference', data: gasrec.business.burned_carbon_reference },
            { name: 'Carbon Credit', data: gasrec.business.is_carbon_credit == "1" ? "Yes" : "Not" },
            {
                name: 'Carbon Credit Start Date',
                data: gasrec.business.start_date_carbon_credit != null ? moment(gasrec.business.start_date_carbon_credit).format(
                    'DD/MM/YYYY'
                ) : "Not applicable"
            },
            {
                name: 'Carbon Credit Expiration Date',
                data: gasrec.business.expiration_date_carbon_credit != null ? moment(gasrec.business.expiration_date_carbon_credit).format(
                    'DD/MM/YYYY'
                ) : "Not applicable"
            },
            {
                name: 'Carbon Credit Methodology',
                data: gasrec.business.methodology_carbon_credit != null ? gasrec.business.methodology_carbon_credit : "Not applicable"
            },
            {
                name: 'Unit Issues CBIO',
                data: gasrec.business.issue_cbio == "1" ? "Yes" : "Not"
            },
            {
                name: 'CBIO issue start date',
                data: gasrec.business.start_date_cbio != null ? moment(gasrec.business.start_date_cbio).format(
                    'DD/MM/YYYY'
                ) : "Not applicable"
            },
            {
                name: 'CBIO Issue Expiration Date',
                data: gasrec.business.expiration_date_cbio != null ? moment(gasrec.business.expiration_date_cbio).format(
                    'DD/MM/YYYY'
                ) : "Not applicable"
            },
        ];

        DeclaracaoRows = [{
            declaration_initial: gasrec.declaration_initial,
            declaration_final: gasrec.declaration_final,
            volume: gasrec.volume,
            first_period: gasrec.recEmission.first_period,
            last_period: gasrec.recEmission.last_period,
        }];
    }

    const classes = useStyles();

    return (
        <>
            {
                gasrec ? (
                    <div className={classes.container} >
                        <h1 className={classes.title} style={{ textAlign: "center", marginTop: "50px", marginBottom: "50px", fontSize: "30px", textTransform: "uppercase", }}>I-REC(G)/GAS-REC Redemption Statement</h1>
                        <div style={{ marginBottom: "30px", maxWidth: "795px" }}>
                            <h4>
                                This statement confirms that the following I-RECS(G)/GAS-RECS have been withdrawn from the market and are therefore no longer transferable or assignable.
                            </h4>
                        </div>

                        <div style={{ marginBottom: "50px" }}>
                            <h2 style={{ textAlign: "center" }} className={classes.title}>Beneficiary Data</h2>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableBody>
                                        {BeneficiarioRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell className={classes.th} style={{ borderRight: "2px solid rgba(224, 224, 224, 1)" }} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">{row.data}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                        <div style={{ marginBottom: "30px", maxWidth: "795px" }}>
                            <h4>
                                Reason for Redemption: {gasrec?.reason_retirement}
                            </h4>
                        </div>

                        <div style={{ marginBottom: "50px" }}>
                            <h2 className={classes.title} style={{ textAlign: "center" }}>Device Data</h2>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableBody>
                                        {DispositivoRows.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell className={classes.th} style={{ borderRight: "2px solid rgba(224, 224, 224, 1)" }} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">{row.data}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div style={{ marginBottom: "50px" }}>
                            <h2 className={classes.title} style={{ textAlign: "center" }}>Retired Certificates</h2>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.th}>ID Certificate</TableCell>
                                            <TableCell className={classes.th}>For Certificate ID</TableCell>
                                            <TableCell className={classes.th}>Number of Certificates</TableCell>
                                            <TableCell className={classes.th}>Production period</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {DeclaracaoRows.map((row, index) => (
                                            <>
                                                <TableRow key={index}>
                                                    <TableCell align="center" component="th" scope="row">
                                                        {row.declaration_initial}
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row">
                                                        {row.declaration_final}
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row">
                                                        {row.volume}
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row">
                                                        {`${moment(row.first_period, "DD/MM/YYYY").format("DD/MM/YYYY")}`}
                                                        <TableCell style={{ display: "flex", flexDirection: "column", alignItems: "center", borderBottom: "0" }}>
                                                            {`${moment(row.last_period, "DD/MM/YYYY").format("DD/MM/YYYY")}`}
                                                        </TableCell>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                ) : (
                    <div className={classes.container}>
                        <h1 className={classes.title} style={{ textAlign: "center", margin: "50px 50px", fontSize: "30px", textTransform: "uppercase", alignSelf: "center" }}>
                            Non-existent statement
                        </h1>
                    </div>
                )
            }
        </>
    )
}

export default Declaration