import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as ClientDashboardTypes,
  Creators as ClientDashboardActions,
} from '../ducks/ClientDashboard';
import api from '../../util/Api';

function* getClientTopNumbers() {
  try {
    const { status, data } = yield call(api.get, '/clientDashboard/topNumbers');
    if (status === 200) {
      yield put(
        ClientDashboardActions.getClientTopNumbersSuccess(data.topNumbers)
      );
    } else {
      yield put(
        ClientDashboardActions.getClientTopNumbersError(
          'Error listing topnumbers'
        )
      );
    }
  } catch {
    yield put(
      ClientDashboardActions.getClientTopNumbersError(
        'Error listing topnumbers'
      )
    );
  }
}

function* getApprovedRecsByYear() {
  try {
    const { status, data } = yield call(
      api.get,
      '/clientDashboard/approvedRecsByYear'
    );
    if (status === 200) {
      yield put(ClientDashboardActions.getApprovedRecsByYearSuccess(data));
    } else {
      yield put(
        ClientDashboardActions.getApprovedRecsByYearError(
          "Error listing approved RECs by year"
        )
      );
    }
  } catch {
    yield put(
      ClientDashboardActions.getApprovedRecsByYearError(
        "Error listing approved RECs by year"
      )
    );
  }
}

function* getAvailableMwhForEmission() {
  try {
    const { status, data } = yield call(
      api.get,
      '/clientDashboard/availableMwhForEmission'
    );
    if (status === 200) {
      yield put(ClientDashboardActions.getAvailableMwhForEmissionSuccess(data));
    } else {
      yield put(
        ClientDashboardActions.getAvailableMwhForEmissionError(
          'Error listing available MWH for issuance'
        )
      );
    }
  } catch {
    yield put(
      ClientDashboardActions.getAvailableMwhForEmissionError(
        'Error listing available MWH for issuance'
      )
    );
  }
}

function* getBusinessBySource() {
  try {
    const { status, data } = yield call(
      api.get,
      '/clientDashboard/businessesBySource'
    );
    if (status === 200) {
      yield put(ClientDashboardActions.getBusinessBySourceSuccess(data));
    } else {
      yield put(
        ClientDashboardActions.getBusinessBySourceError(
          'Error listing projects by energy source'
        )
      );
    }
  } catch {
    yield put(
      ClientDashboardActions.getBusinessBySourceError(
        'Error listing projects by energy source'
      )
    );
  }
}

function* getLastApprovedRecs() {
  try {
    const { status, data } = yield call(
      api.get,
      '/clientDashboard/lastApprovedRecs'
    );
    if (status === 200) {
      yield put(ClientDashboardActions.getLastApprovedRecsSuccess(data));
    } else {
      yield put(
        ClientDashboardActions.getLastApprovedRecsError(
          "Error listing the last approved REC's"
        )
      );
    }
  } catch {
    yield put(
      ClientDashboardActions.getLastApprovedRecsError(
        "Error listing the last approved REC's"
      )
    );
  }
}

function* getClientTopNumbersWatcher() {
  yield takeLatest(
    ClientDashboardTypes.GET_CLIENT_TOP_NUMBERS,
    getClientTopNumbers
  );
}

function* getApprovedRecsByYearWatcher() {
  yield takeLatest(
    ClientDashboardTypes.GET_APPROVED_RECS_BY_YEAR,
    getApprovedRecsByYear
  );
}

function* getAvailableMwhForEmissionWatcher() {
  yield takeLatest(
    ClientDashboardTypes.GET_AVAILABLE_MWH_FOR_EMISSION,
    getAvailableMwhForEmission
  );
}

function* getBusinessBySourceWatcher() {
  yield takeLatest(
    ClientDashboardTypes.GET_BUSINESS_BY_SOURCE,
    getBusinessBySource
  );
}

function* getLastApprovedRecsWatcher() {
  yield takeLatest(
    ClientDashboardTypes.GET_LAST_APPROVED_RECS,
    getLastApprovedRecs
  );
}

export default function* rootSaga() {
  yield all([
    fork(getClientTopNumbersWatcher),
    fork(getApprovedRecsByYearWatcher),
    fork(getAvailableMwhForEmissionWatcher),
    fork(getBusinessBySourceWatcher),
    fork(getLastApprovedRecsWatcher),
  ]);
}
