export const Types = {
  GET_RECS: 'GET_RECS',
  GET_RECS_SUCCESS: 'GET_RECS_SUCCESS',
  GET_RECS_ERROR: 'GET_RECS_ERROR',

  GET_PDF: 'GET_PDF',
  GET_PDF_ERROR: 'GET_PDF_ERROR',
  GET_PDF_SUCCESS: 'GET_PDF_SUCCESS',
  GET_PDF_RESET: 'GET_PDF_RESET',

  POST_RECS: 'POST_RECS',
  POST_RECS_SUCCESS: 'POST_RECS_SUCCESS',
  POST_RECS_ERROR: 'POST_RECS_ERROR',
  POST_RECS_RESET: 'POST_RECS_RESET',

  GENERATION_RECS: 'GENERATION_RECS',
  GENERATION_RECS_SUCCESS: 'GENERATION_RECS_SUCCESS',
  GENERATION_RECS_ERROR: 'GENERATION_RECS_ERROR',
  GENERATION_RECS_RESET: 'GENERATION_RECS_RESET',

  PUT_RECS: 'PUT_RECS',
  PUT_RECS_SUCCESS: 'PUT_RECS_SUCCESS',
  PUT_RECS_ERROR: 'PUT_RECS_ERROR',
  PUT_RECS_RESET: 'PUT_RECS_RESET',

  APPROVE_REC: 'APPROVE_REC',
  APPROVE_REC_SUCCESS: 'APPROVE_REC_SUCCESS',
  APPROVE_REC_ERROR: 'APPROVE_REC_ERROR',
  APPROVE_REC_RESET: 'APPROVE_REC_RESET',

  BLOCK_REC: 'BLOCK_REC',
  BLOCK_REC_SUCCESS: 'BLOCK_REC_SUCCESS',
  BLOCK_REC_ERROR: 'BLOCK_REC_ERROR',
  BLOCK_REC_RESET: 'BLOCK_REC_RESET',

  REFUSE_REC: 'REFUSE_REC',
  REFUSE_REC_SUCCESS: 'REFUSE_REC_SUCCESS',
  REFUSE_REC_ERROR: 'REFUSE_REC_ERROR',
  REFUSE_REC_RESET: 'REFUSE_REC_RESET',

  GET_RECS_IMPORT: 'GET_RECS_IMPORT',
  GET_RECS_IMPORT_SUCCESS: 'GET_RECS_IMPORT_SUCCESS',
  GET_RECS_IMPORT_ERROR: 'GET_RECS_IMPORT_ERROR',

  COMMIT_IMPORT: 'COMMIT_IMPORT',
  COMMIT_IMPORT_SUCCESS: 'COMMIT_IMPORT_SUCCESS',
  COMMIT_IMPORT_ERROR: 'COMMIT_IMPORT_ERROR',

  GET_RECS_BUSINESS: 'GET_RECS_BUSINESS',
  GET_RECS_BUSINESS_SUCCESS: 'GET_RECS_BUSINESS_SUCCESS',
  GET_RECS_BUSINESS_ERROR: 'GET_RECS_BUSINESS_ERROR',
};

const INIT_STATE = {
  data: null,
  loading: false,
  error: false,

  amount: 10,

  dataImport: null,
  loadingImport: false,
  errorImport: false,

  amountImport: 10,

  dataBusiness: null,
  loadingBusiness: false,
  errorBusiness: false,

  amountBusiness: 10,

  postLoading: false,
  postSuccess: false,
  postError: false,

  generationLoading: false,
  generationSuccess: false,
  generationError: false,
  message: null,

  putLoading: false,
  putSuccess: false,
  putError: false,

  approveLoading: false,
  approveSuccess: false,
  approveError: false,
  approveErrorMessage: '',

  blockLoading: false,
  blockSuccess: false,
  blockError: false,

  commitLoading: false,
  commitSuccess: false,
  commitError: false,

  refuseLoading: false,
  refuseSuccess: false,
  refuseError: false,

  getPDFLoading: false,
  getPDFError: false,
};

const REC_ERRORS = {
  CEG_NOT_FOUND:
    'CEG not registered on the platform. Contact system administrator',
  REGISTRANT_NOT_FOUND:
    'Registrant not found. Contact system administrator',
  BUSINESS_NOT_FOUND:
    'Project not found. Contact system administrator',
  SOURCE_NOT_ASSIGNED:
    'Power source not found. Contact system administrator',
  EXCEEDS_LIMIT:
    'The data included showed some divergence regarding the amount of MWh in the month.',
  GENERATION_ALREADY_SUBMITTED:
    'Generation record for the current month has already been registered',
  FILE_NOT_FOUND: 'Error sending file',
};

export default function Rec(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_RECS:
      return {
        ...state,
        data: null,
        loading: true,
        error: false,
      };
    case Types.GET_RECS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        amount: action.payload.total,
        loading: false,
        error: false,
      };
    case Types.GET_RECS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: true,
      };
    case Types.GET_PDF:
      return {
        ...state,
        getPDFLoading: true,
        getPDFError: false,
      };
    case Types.GET_PDF_SUCCESS:
      return {
        ...state,
        getPDFLoading: false,
        getPDFError: false,
      };
    case Types.GET_PDF_ERROR:
      return {
        ...state,
        getPDFLoading: false,
        getPDFError: true,
      };
    case Types.GET_PDF_RESET:
      return {
        ...state,
        getPDFLoading: false,
        getPDFError: false,
      };

    case Types.GET_RECS_IMPORT:
      return {
        ...state,
        dataImport: null,
        loadingImport: true,
        errorImport: false,
      };
    case Types.GET_RECS_IMPORT_SUCCESS:
      return {
        ...state,
        dataImport: action.payload.data,
        amountImport: action.payload.total,
        loadingImport: false,
        errorImport: false,
      };
    case Types.GET_RECS_IMPORT_ERROR:
      return {
        ...state,
        dataImport: null,
        loadingImport: false,
        errorImport: true,
      };

    case Types.GET_RECS_BUSINESS:
      return {
        ...state,
        dataBusiness: null,
        loadingBusiness: true,
        errorBusiness: false,
      };
    case Types.GET_RECS_BUSINESS_SUCCESS:
      return {
        ...state,
        dataBusiness: action.payload.data,
        amountBusiness: action.payload.total,
        loadingBusiness: false,
        errorBusiness: false,
      };
    case Types.GET_RECS_BUSINESS_ERROR:
      return {
        ...state,
        dataBusiness: null,
        loadingBusiness: false,
        errorBusiness: true,
      };

    case Types.POST_RECS:
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
        postError: false,
      };
    case Types.POST_RECS_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
        postError: false,
      };
    case Types.POST_RECS_ERROR:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: action.payload,
      };
    case Types.POST_RECS_RESET:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        postError: false,
      };

    case Types.GENERATION_RECS:
      return {
        ...state,
        message: null,
        generationLoading: true,
        generationSuccess: false,
        generationError: false,
      };
    case Types.GENERATION_RECS_SUCCESS:
      return {
        ...state,
        generationLoading: false,
        generationSuccess: true,
        generationError: false,
      };
    case Types.GENERATION_RECS_ERROR:
      return {
        ...state,
        generationLoading: false,
        generationSuccess: false,
        generationError: true,
        message:
          REC_ERRORS?.[action.payload?.message] || action.payload?.message,
      };
    case Types.GENERATION_RECS_RESET:
      return {
        ...state,
        generationLoading: false,
        generationSuccess: false,
        generationError: false,
      };

    case Types.PUT_RECS:
      return {
        ...state,
        putLoading: true,
        putSuccess: false,
        putError: false,
      };
    case Types.PUT_RECS_SUCCESS:
      return {
        ...state,
        putLoading: false,
        putSuccess: true,
        putError: false,
      };
    case Types.PUT_RECS_ERROR:
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: true,
      };
    case Types.PUT_RECS_RESET:
      return {
        ...state,
        putLoading: false,
        putSuccess: false,
        putError: false,
      };

    case Types.APPROVE_REC:
      return {
        ...state,
        approveLoading: true,
        approveSuccess: false,
        approveError: false,
      };
    case Types.APPROVE_REC_SUCCESS:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: true,
        approveError: false,
      };
    case Types.APPROVE_REC_ERROR:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: true,
        approveErrorMessage: action.message,
      };
    case Types.APPROVE_REC_RESET:
      return {
        ...state,
        approveLoading: false,
        approveSuccess: false,
        approveError: false,
      };

    case Types.COMMIT_IMPORT:
      return {
        ...state,
        commitLoading: true,
        commitSuccess: false,
        commitError: false,
      };
    case Types.COMMIT_IMPORT_SUCCESS:
      return {
        ...state,
        commitLoading: false,
        commitSuccess: action.payload,
        commitError: false,
      };
    case Types.COMMIT_IMPORT_ERROR:
      return {
        ...state,
        commitLoading: false,
        commitSuccess: false,
        commitError: action.payload,
      };

    case Types.BLOCK_REC:
      return {
        ...state,
        blockLoading: true,
        blockSuccess: false,
        blockError: false,
      };
    case Types.BLOCK_REC_SUCCESS:
      return {
        ...state,
        blockLoading: false,
        blockSuccess: true,
        blockError: false,
      };
    case Types.BLOCK_REC_ERROR:
      return {
        ...state,
        blockLoading: false,
        blockSuccess: false,
        blockError: true,
      };
    case Types.BLOCK_REC_RESET:
      return {
        ...state,
        blockLoading: false,
        blockSuccess: false,
        blockError: false,
      };

    case Types.REFUSE_REC:
      return {
        ...state,
        refuseLoading: true,
        refuseSuccess: false,
        refuseError: false,
      };
    case Types.REFUSE_REC_SUCCESS:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: true,
        refuseError: false,
      };
    case Types.REFUSE_REC_ERROR:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: true,
      };
    case Types.REFUSE_REC_RESET:
      return {
        ...state,
        refuseLoading: false,
        refuseSuccess: false,
        refuseError: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getRecs: payload => ({
    type: Types.GET_RECS,
    payload,
  }),
  getPdf: payload => ({
    type: Types.GET_PDF,
    payload,
  }),
  getPdfError: () => ({
    type: Types.GET_PDF_ERROR,
  }),
  getPdfSuccess: () => ({
    type: Types.GET_PDF_SUCCESS,
  }),
  getPdfReset: () => ({
    type: Types.GET_PDF_RESET,
  }),
  getRecsSuccess: payload => ({
    type: Types.GET_RECS_SUCCESS,
    payload,
  }),
  getRecsError: () => ({
    type: Types.GET_RECS_ERROR,
  }),

  getRecsImport: payload => ({
    type: Types.GET_RECS_IMPORT,
    payload,
  }),
  getRecsImportSuccess: payload => ({
    type: Types.GET_RECS_IMPORT_SUCCESS,
    payload,
  }),
  getRecsImportError: () => ({
    type: Types.GET_RECS_IMPORT_ERROR,
  }),

  getRecsBusiness: payload => ({
    type: Types.GET_RECS_BUSINESS,
    payload,
  }),
  getRecsBusinessSuccess: payload => ({
    type: Types.GET_RECS_BUSINESS_SUCCESS,
    payload,
  }),
  getRecsBusinessError: () => ({
    type: Types.GET_RECS_BUSINESS_ERROR,
  }),

  postRecs: payload => ({
    type: Types.POST_RECS,
    payload,
  }),
  postRecsSuccess: () => ({
    type: Types.POST_RECS_SUCCESS,
  }),
  postRecsError: payload => ({
    type: Types.POST_RECS_ERROR,
    payload,
  }),
  postRecsReset: () => ({
    type: Types.POST_RECS_RESET,
  }),

  generationRecs: payload => ({
    type: Types.GENERATION_RECS,
    payload,
  }),
  generationRecsSuccess: () => ({
    type: Types.GENERATION_RECS_SUCCESS,
  }),
  generationRecsError: payload => ({
    type: Types.GENERATION_RECS_ERROR,
    payload,
  }),
  generationRecsReset: () => ({
    type: Types.GENERATION_RECS_RESET,
  }),

  putRecs: payload => ({
    type: Types.PUT_RECS,
    payload,
  }),
  putRecsSuccess: () => ({
    type: Types.PUT_RECS_SUCCESS,
  }),
  putRecsError: () => ({
    type: Types.PUT_RECS_ERROR,
  }),
  putRecsReset: () => ({
    type: Types.PUT_RECS_RESET,
  }),

  approveRec: payload => ({
    type: Types.APPROVE_REC,
    payload,
  }),
  approveRecSuccess: () => ({
    type: Types.APPROVE_REC_SUCCESS,
  }),
  approveRecError: message => ({
    type: Types.APPROVE_REC_ERROR,
    message,
  }),
  approveRecReset: () => ({
    type: Types.APPROVE_REC_RESET,
  }),

  commitImport: payload => ({
    type: Types.COMMIT_IMPORT,
    payload,
  }),
  commitImportSuccess: payload => ({
    type: Types.COMMIT_IMPORT_SUCCESS,
    payload,
  }),
  commitImportError: payload => ({
    type: Types.COMMIT_IMPORT_ERROR,
    payload,
  }),

  blockRec: payload => ({
    type: Types.BLOCK_REC,
    payload,
  }),
  blockRecSuccess: () => ({
    type: Types.BLOCK_REC_SUCCESS,
  }),
  blockRecError: () => ({
    type: Types.BLOCK_REC_ERROR,
  }),
  blockRecReset: () => ({
    type: Types.BLOCK_REC_RESET,
  }),

  refuseRec: payload => ({
    type: Types.REFUSE_REC,
    payload,
  }),
  refuseRecSuccess: () => ({
    type: Types.REFUSE_REC_SUCCESS,
  }),
  refuseRecError: () => ({
    type: Types.REFUSE_REC_ERROR,
  }),
  refuseRecReset: () => ({
    type: Types.REFUSE_REC_RESET,
  }),
};
