export const Types = {
    GET_PARTICIPANT_TOP_NUMBERS: 'GET_PARTICIPANT_TOP_NUMBERS',
    GET_PARTICIPANT_TOP_NUMBERS_SUCCESS: 'GET_PARTICIPANT_TOP_NUMBERS_SUCCESS',
    GET_PARTICIPANT_TOP_NUMBERS_ERROR: 'GET_PARTICIPANT_TOP_NUMBERS_ERROR',
  };
  
  const INIT_STATE = {
    topNumbers: null,
    topNumbersLoading: false,
    topNumbersError: null,
  };
  
  export default function participantDashboard(state = INIT_STATE, action) {
    switch (action.type) {
      case Types.GET_PARTICIPANT_TOP_NUMBERS:
        return {
          ...state,
          topNumbers: null,
          topNumbersLoading: true,
          topNumbersError: null,
        };
      case Types.GET_PARTICIPANT_TOP_NUMBERS_SUCCESS:
        return {
          ...state,
          topNumbers: action.payload,
          topNumbersLoading: false,
          topNumbersError: null,
        };
      case Types.GET_PARTICIPANT_TOP_NUMBERS_ERROR:
        return {
          ...state,
          topNumbers: null,
          topNumbersLoading: false,
          topNumbersError: action.payload,
        };
  
      default:
        return state;
    }
  }
  
  export const Creators = {
    getParticipantTopNumbers: () => ({
      type: Types.GET_PARTICIPANT_TOP_NUMBERS
    }),

    getParticipantTopNumbersSuccess: payload => ({
      type: Types.GET_PARTICIPANT_TOP_NUMBERS_SUCCESS,
      payload,
    }),
    
    getParticipantTopNumbersError: payload => ({
      type: Types.GET_PARTICIPANT_TOP_NUMBERS_ERROR,
      payload,
    }),
  
  };
  