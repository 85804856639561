//ducks é o reducer da aplicação

export const Types = { //tipos 
  GET_CONSULT: 'GET_CONSULT',
  GET_CONSULT_SUCCESS: 'GET_CONSULT_SUCCESS',
  GET_CONSULT_ERROR: 'GET_CONSULT_ERROR',

};

const INIT_STATE = { //estado inicial do reducer
  data: null,
  loading: null,
  error: null,
};

export default function Consult(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_CONSULT:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_CONSULT_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_CONSULT_ERROR:
      return {
        ...state,
        data: false,
        loading: false,
        error: true,
      };
    default: return state
  }
}

export const Creators = { //actions da aplicação
  getConsult: payload => ({
    type: Types.GET_CONSULT,
    payload,
  }),
  getConsultSuccess: payload => ({
    type: Types.GET_CONSULT_SUCCESS,
    payload,
  }),
  getConsultError: payload => ({
    type: Types.GET_CONSULT_ERROR,
    payload,
  }),
};
