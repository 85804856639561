import { combineReducers } from 'redux';
import auth from './Auth';
import settings from './Setting';
import enterprise from './Enterprise';
import registrant from './Registrant';
import admin from './Admin';
import participant from './Participant';
import notaFiscal from './NotaFiscal';
import notaFiscalParticipant from './NotaFiscalParticipant';
import ceg from './Ceg';
import rdms from './Rdm';
import rec from './Rec';
import user from './User';
import source from './Source';
import ibge from './Ibge';
import technology from './Tecnology';
import cep from './Cep';
import file from './File';
import adminDashboard from './AdminDashboard';
import clientDashboard from './ClientDashboard';
import participantDashboard from './ParticipantDashboard';
import clientParticipant from './ClientParticipant';
import recRetired from './RecRetired';
import recTransfer from './RecTransfer';
import recSaving from './RecSaving';
import transationRetired from './TransationRetired';
import generation from './Generation';
import recoverPassword from './RecoverPassword';
import consult from './Consult';
import issuerDashboard from './IssuerDashboard';
import issuer from './Issuer';
import productionDetail from './ProductionDetail';
import substrate from './Substrate';
import useBiogas from './UseBiogas';
import applicationBiogas from './ApplicationBiogas';

const rootReducer = combineReducers({
  auth,
  settings,
  enterprise,
  registrant,
  admin,
  participant,
  notaFiscal,
  notaFiscalParticipant,
  ceg,
  rdms,
  rec,
  user,
  source,
  ibge,
  technology,
  cep,
  file,
  adminDashboard,
  clientDashboard,
  clientParticipant,
  recRetired,
  recTransfer,
  recSaving,
  transationRetired,
  generation,
  recoverPassword,
  consult,
  participantDashboard,
  issuerDashboard,
  issuer,
  productionDetail,
  substrate,
  useBiogas,
  applicationBiogas,
});

export default rootReducer;
