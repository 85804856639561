export const Types = {
  SEND_EMAIL: 'SEND_EMAIL',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_ERROR: 'SEND_EMAIL_ERROR',

  NEW_PASSWORD: 'NEW_PASSWORD',
  NEW_PASSWORD_SUCCESS: 'NEW_PASSWORD_SUCCESS',
  NEW_PASSWORD_ERROR: 'NEW_PASSWORD_ERROR',

  CLEAR_STATE: 'CLEAR_STATE',
};

const INIT_STATE = {
  sentSuccess: null,
  loading: false,
  error: false,
};

export default function RecoverPassword(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.SEND_EMAIL: {
      return {
        ...state,
        sentSuccess: false,
        loading: true,
        error: false,
      };
    }
    case Types.SEND_EMAIL_SUCCESS: {
      return {
        ...state,
        sentSuccess: true,
        loading: false,
        error: false,
      };
    }
    case Types.SEND_EMAIL_ERROR: {
      return {
        ...state,
        sentSuccess: false,
        loading: false,
        error: true,
      };
    }
    case Types.NEW_PASSWORD: {
      return {
        ...state,
        sentSuccess: false,
        loading: true,
        error: false,
      };
    }
    case Types.NEW_PASSWORD_SUCCESS: {
      return {
        ...state,
        sentSuccess: true,
        loading: false,
        error: false,
      };
    }
    case Types.NEW_PASSWORD_ERROR: {
      return {
        ...state,
        sentSuccess: false,
        loading: false,
        error: true,
      };
    }
    case Types.CLEAR_STATE: {
      return {
        ...state,
        sentSuccess: false,
        loading: false,
        error: false,
      };
    }
    default:
      return state;
  }
}

export const Creators = {
  clearState: payload => ({
    type: Types.CLEAR_STATE,
  }),
  sendEmail: payload => ({
    type: Types.SEND_EMAIL,
    payload,
  }),
  sendEmailSuccess: () => ({
    type: Types.SEND_EMAIL_SUCCESS,
  }),
  sendEmailError: () => ({
    type: Types.SEND_EMAIL_ERROR,
  }),
  newPassword: payload => ({
    type: Types.NEW_PASSWORD,
    payload,
  }),
  newPasswordSuccess: () => ({
    type: Types.NEW_PASSWORD_SUCCESS,
  }),
  newPasswordError: () => ({
    type: Types.NEW_PASSWORD_ERROR,
  }),
};
