export const Types = {
  GET_ESTADOS: 'GET_ESTADOS',
  GET_ESTADOS_SUCCESS: 'GET_ESTADOS_SUCCESS',
  GET_ESTADOS_ERROR: 'GET_ESTADOS_ERROR',
  GET_CIDADES: 'GET_CIDADES',
  GET_CIDADES_SUCCESS: 'GET_CIDADES_SUCCESS',
  GET_CIDADES_ERROR: 'GET_CIDADES_ERROR',
};

const INIT_STATE = {
  loading: false,
  estados: [],
  cidades: [],
};

export default function Auth(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_ESTADOS:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_ESTADOS_SUCCESS:
      return {
        ...state,
        estados: action.payload,
        loading: false,
      };
    case Types.GET_ESTADOS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.GET_CIDADES:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_CIDADES_SUCCESS:
      return {
        ...state,
        cidades: action.payload,
        loading: false,
      };
    case Types.GET_CIDADES_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getEstados: () => ({
    type: Types.GET_ESTADOS,
  }),
  getEstadosSuccess: payload => ({
    type: Types.GET_ESTADOS_SUCCESS,
    payload,
  }),
  getEstadosError: () => ({
    type: Types.GET_ESTADOS_ERROR,
  }),

  getCidades: payload => ({
    type: Types.GET_CIDADES,
    payload,
  }),
  getCidadesSuccess: payload => ({
    type: Types.GET_CIDADES_SUCCESS,
    payload,
  }),
  getCidadesError: () => ({
    type: Types.GET_CIDADES_ERROR,
  }),
};
