export const Types = {
  GET_GENERATION: 'GET_GENERATION',
  GET_GENERATION_SUCCESS: 'GET_GENERATION_SUCCESS',
  GET_GENERATION_ERROR: 'GET_GENERATION_ERROR',
};

const INIT_STATE = {
  data: null,
  loading: false,
  error: null,

  amount: 10,
};

export default function Generation(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_GENERATION:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_GENERATION_SUCCESS:
      return {
        ...state,
        data: action.payload.data || action.payload,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_GENERATION_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

export const Creators = {
  getGeneration: payload => ({
    type: Types.GET_GENERATION,
    payload,
  }),
  getGenerationSuccess: payload => ({
    type: Types.GET_GENERATION_SUCCESS,
    payload,
  }),
  getGenerationError: payload => ({
    type: Types.GET_GENERATION_ERROR,
    payload,
  }),
};
