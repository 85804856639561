import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { Types as AdminTypes, Creators as AdminActions } from '../ducks/Admin';
import api from '../../util/Api';

function* getAdmins({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/admins', {
      params: payload,
    });
    if (status === 200) {
      yield put(AdminActions.getAdminsSuccess(data));
    }
  } catch (error) {
    yield put(AdminActions.getAdminsError('Error listing Administrators'));
  }
}

function* postAdmin({ payload }) {
  try {
    const { status } = yield call(api.post, '/admins', payload);
    if (status === 200) {
      yield put(AdminActions.postAdminSuccess());
    }
  } catch (error) {
    yield put(AdminActions.postAdminError('Error registering admin'));
  }
}

function* getAdminById({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/admins/${payload}`);
    if (status === 200) {
      yield put(AdminActions.getAdminByIdSuccess(data));
    }
  } catch (error) {
    yield put(AdminActions.getAdminByIdError('Error listing Administrator'));
  }
}

function* putAdmin({ payload }) {
  const { adminId } = payload;
  try {
    const { status } = yield call(api.put, `/admins/${adminId}`, payload.data);
    if (status === 200) {
      yield put(AdminActions.putAdminSuccess());
    }
  } catch (error) {
    yield put(AdminActions.putAdminError('Error editing admin'));
  }
}

function* getAdminsWatcher() {
  yield takeLatest(AdminTypes.GET_ADMINS, getAdmins);
}

function* postAdminWatcher() {
  yield takeLatest(AdminTypes.POST_ADMIN, postAdmin);
}

function* getAdminByIdWatcher() {
  yield takeLatest(AdminTypes.GET_ADMIN_BY_ID, getAdminById);
}

function* putAdminWatcher() {
  yield takeLatest(AdminTypes.PUT_ADMIN, putAdmin);
}

export default function* rootSaga() {
  yield all([
    fork(getAdminsWatcher),
    fork(postAdminWatcher),
    fork(getAdminByIdWatcher),
    fork(putAdminWatcher),
  ]);
}
