import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as ProductionDetailTypes,
  Creators as ProductionDetailActions,
} from '../ducks/ProductionDetail';
import api from '../../util/Api';

function* getProductionDetails({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/productionDetail', {
      params: payload,
    });
    if (status === 200) {
      yield put(ProductionDetailActions.getProductionDetailsSuccess(data));
    }
  } catch (error) {
    yield put(
      ProductionDetailActions.getProductionDetailsError('Error listing Production Data')
    );
  }
}

function* postProductionDetail({ payload }) {
  try {
    const { status } = yield call(api.post, '/productionDetail/create', payload, {
      headers: {
        ...(payload instanceof FormData
          ? { 'Content-Type': 'multipart/form-data' }
          : {}),
      },
    });
    if (status === 200) {
      yield put(ProductionDetailActions.postProductionDetailSuccess());
    }
  } catch (error) {
    yield put(ProductionDetailActions.postProductionDetailError(error?.response?.data));
  }
}

function* getProductionDetailById({ payload }) {
  const { id, params, edit } = payload;
  try {
    const { status, data } = yield call(api.get, `/productionDetail/${id}`, {
      params,
    });
    if (status === 200) {
      if (edit && data?.approved !== 'approved') {
        yield put(
          ProductionDetailActions.getProductionDetailByIdError(
            'Production data not found'
          )
        );
      }
      yield put(ProductionDetailActions.getProductionDetailByIdSuccess(data));
    }
  } catch (error) {
    yield put(
      ProductionDetailActions.getProductionDetailByIdError('Production Data not found')
    );
  }
}

function* requestAudit({ payload }) {
  try {
    const { status } = yield call(api.get, `/productionDetail/requestAudit/${payload}`);
    if (status === 200) {
      yield put(ProductionDetailActions.getRequestAuditSuccess());
    }
  } catch (error) {
    yield put(
      ProductionDetailActions.getRequestAuditError('Error sending email')
    );
  }
}

function* putProductionDetail({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/productionDetail/update/${id}`, data, {
      headers: {
        ...(data instanceof FormData
          ? { 'Content-Type': 'multipart/form-data' }
          : {}),
      },
    });
    if (status === 200) {
      yield put(ProductionDetailActions.putProductionDetailSuccess());
    }
  } catch (error) {
    yield put(
      ProductionDetailActions.putProductionDetailError('Error editing Production Data')
    );
  }
}

function* deleteProductionDetail({ payload }) {
  try {
    const { status } = yield call(api.delete, `/productionDetail/${payload}`);
    if (status === 200) {
      yield put(ProductionDetailActions.deleteProductionDetailSuccess());
    }
  } catch (error) {
    yield put(
      ProductionDetailActions.deleteProductionDetailError('Error deleting Production Data')
    );
  }
}

function* approveProductionDetail({ payload }) {
  try {
    const { status } = yield call(api.post, `/productionDetail/approve/${payload}`);
    if (status === 200) {
      yield put(ProductionDetailActions.approveProductionDetailSuccess());
    }
  } catch (error) {
    yield put(
      ProductionDetailActions.approveProductionDetailError(error?.response?.data?.message)
    );
  }
}

function* refuseProductionDetail({ payload }) {

  const { id, data } = payload

  try {
    const { status } = yield call(api.post, `/productionDetail/refuse/${id}`, data);
    if (status === 200) {
      yield put(ProductionDetailActions.refuseProductionDetailSuccess());
    }
  } catch (error) {
    yield put(ProductionDetailActions.refuseProductionDetailError());
  }
}

function* getProductionDetailsWatcher() {
  yield takeLatest(ProductionDetailTypes.GET_PRODUCTION_DETAILS, getProductionDetails);
}

function* postProductionDetailWatcher() {
  yield takeLatest(ProductionDetailTypes.POST_PRODUCTION_DETAIL, postProductionDetail);
}

function* getProductionDetailByIdWatcher() {
  yield takeLatest(ProductionDetailTypes.GET_PRODUCTION_DETAIL_BY_ID, getProductionDetailById);
}

function* requestAuditWatcher() {
  yield takeLatest(ProductionDetailTypes.GET_REQUEST_AUDIT, requestAudit);
}

function* putProductionDetailWatcher() {
  yield takeLatest(ProductionDetailTypes.PUT_PRODUCTION_DETAIL, putProductionDetail);
}

function* deleteProductionDetailWatcher() {
  yield takeLatest(ProductionDetailTypes.DELETE_PRODUCTION_DETAIL, deleteProductionDetail);
}

function* approveProductionDetailWatcher() {
  yield takeLatest(ProductionDetailTypes.APPROVE_PRODUCTION_DETAIL, approveProductionDetail);
}


function* refuseProductionDetailWatcher() {
  yield takeLatest(ProductionDetailTypes.REFUSE_PRODUCTION_DETAIL, refuseProductionDetail);
}

export default function* rootSaga() {
  yield all([
    fork(getProductionDetailsWatcher),
    fork(postProductionDetailWatcher),
    fork(getProductionDetailByIdWatcher),
    fork(requestAuditWatcher),
    fork(putProductionDetailWatcher),
    fork(deleteProductionDetailWatcher),
    fork(approveProductionDetailWatcher),
    fork(refuseProductionDetailWatcher),
  ]);
}
