export const Types = {
  GET_CLIENT_TOP_NUMBERS: 'GET_CLIENT_TOP_NUMBERS',
  GET_CLIENT_TOP_NUMBERS_SUCCESS: 'GET_CLIENT_TOP_NUMBERS_SUCCESS',
  GET_CLIENT_TOP_NUMBERS_ERROR: 'GET_CLIENT_TOP_NUMBERS_ERROR',

  GET_APPROVED_RECS_BY_YEAR: 'GET_APPROVED_RECS_BY_YEAR',
  GET_APPROVED_RECS_BY_YEAR_SUCCESS: 'GET_APPROVED_RECS_BY_YEAR_SUCCESS',
  GET_APPROVED_RECS_BY_YEAR_ERROR: 'GET_APPROVED_RECS_BY_YEAR_ERROR',

  GET_AVAILABLE_MWH_FOR_EMISSION: 'GET_AVAILABLE_MWH_FOR_EMISSION',
  GET_AVAILABLE_MWH_FOR_EMISSION_SUCCESS:
    'GET_AVAILABLE_MWH_FOR_EMISSION_SUCCESS',
  GET_AVAILABLE_MWH_FOR_EMISSION_ERROR: 'GET_AVAILABLE_MWH_FOR_EMISSION_ERROR',

  GET_BUSINESS_BY_SOURCE: 'GET_BUSINESS_BY_SOURCE',
  GET_BUSINESS_BY_SOURCE_SUCCESS: 'GET_BUSINESS_BY_SOURCE_SUCCESS',
  GET_BUSINESS_BY_SOURCE_ERROR: 'GET_BUSINESS_BY_SOURCE_ERROR',

  GET_LAST_APPROVED_RECS: 'GET_LAST_APPROVED_RECS',
  GET_LAST_APPROVED_RECS_SUCCESS: 'GET_LAST_APPROVED_RECS_SUCCESS',
  GET_LAST_APPROVED_RECS_ERROR: 'GET_LAST_APPROVED_RECS_ERROR',
};

const INIT_STATE = {
  topNumbers: {},
  topNumbersLoading: false,
  topNumbersError: null,

  approvedRecsByYear: {},
  approvedRecsByYearLoading: false,
  approvedRecsByYearError: null,

  availableMwhForEmission: {},
  availableMwhForEmissionLoading: false,
  availableMwhForEmissionError: null,

  businessBySource: {},
  businessBySourceLoading: false,
  businessBySourceError: null,

  leatApprovedRecs: {},
  leatApprovedRecsLoading: false,
  leatApprovedRecsError: null,
};

export default function clientDashboard(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_CLIENT_TOP_NUMBERS:
      return {
        ...state,
        topNumbers: {},
        topNumbersLoading: true,
        topNumbersError: null,
      };
    case Types.GET_CLIENT_TOP_NUMBERS_SUCCESS:
      return {
        ...state,
        topNumbers: action.payload,
        topNumbersLoading: false,
        topNumbersError: null,
      };
    case Types.GET_CLIENT_TOP_NUMBERS_ERROR:
      return {
        ...state,
        topNumbers: {},
        topNumbersLoading: false,
        topNumbersError: action.payload,
      };

    case Types.GET_APPROVED_RECS_BY_YEAR:
      return {
        ...state,
        approvedRecsByYear: {},
        approvedRecsByYearLoading: true,
        approvedRecsByYearError: null,
      };
    case Types.GET_APPROVED_RECS_BY_YEAR_SUCCESS:
      return {
        ...state,
        approvedRecsByYear: action.payload,
        approvedRecsByYearLoading: false,
        approvedRecsByYearError: null,
      };
    case Types.GET_APPROVED_RECS_BY_YEAR_ERROR:
      return {
        ...state,
        approvedRecsByYear: {},
        approvedRecsByYearLoading: false,
        approvedRecsByYearError: action.payload,
      };

    case Types.GET_AVAILABLE_MWH_FOR_EMISSION:
      return {
        ...state,
        availableMwhForEmission: {},
        availableMwhForEmissionLoading: true,
        availableMwhForEmissionError: null,
      };
    case Types.GET_AVAILABLE_MWH_FOR_EMISSION_SUCCESS:
      return {
        ...state,
        availableMwhForEmission: action.payload,
        availableMwhForEmissionLoading: false,
        availableMwhForEmissionError: null,
      };
    case Types.GET_AVAILABLE_MWH_FOR_EMISSION_ERROR:
      return {
        ...state,
        availableMwhForEmission: {},
        availableMwhForEmissionLoading: false,
        availableMwhForEmissionError: action.payload,
      };

    case Types.GET_BUSINESS_BY_SOURCE:
      return {
        ...state,
        businessBySource: {},
        businessBySourceLoading: true,
        businessBySourceError: null,
      };
    case Types.GET_BUSINESS_BY_SOURCE_SUCCESS:
      return {
        ...state,
        businessBySource: action.payload,
        businessBySourceLoading: false,
        businessBySourceError: null,
      };
    case Types.GET_BUSINESS_BY_SOURCE_ERROR:
      return {
        ...state,
        businessBySource: {},
        businessBySourceLoading: false,
        businessBySourceError: action.payload,
      };

    case Types.GET_LAST_APPROVED_RECS:
      return {
        ...state,
        lastApprovedRecs: {},
        lastApprovedRecsLoading: true,
        lastApprovedRecsError: null,
      };
    case Types.GET_LAST_APPROVED_RECS_SUCCESS:
      return {
        ...state,
        lastApprovedRecs: action.payload,
        lastApprovedRecsLoading: false,
        lastApprovedRecsError: null,
      };
    case Types.GET_LAST_APPROVED_RECS_ERROR:
      return {
        ...state,
        lastApprovedRecs: {},
        lastApprovedRecsLoading: false,
        lastApprovedRecsError: action.payload,
      };

    default:
      return state;
  }
}

export const Creators = {
  getClientTopNumbers: () => ({
    type: Types.GET_CLIENT_TOP_NUMBERS,
  }),
  getClientTopNumbersSuccess: payload => ({
    type: Types.GET_CLIENT_TOP_NUMBERS_SUCCESS,
    payload,
  }),
  getClientTopNumbersError: payload => ({
    type: Types.GET_CLIENT_TOP_NUMBERS_ERROR,
    payload,
  }),

  getApprovedRecsByYear: () => ({
    type: Types.GET_APPROVED_RECS_BY_YEAR,
  }),
  getApprovedRecsByYearSuccess: payload => ({
    type: Types.GET_APPROVED_RECS_BY_YEAR_SUCCESS,
    payload,
  }),
  getApprovedRecsByYearError: payload => ({
    type: Types.GET_APPROVED_RECS_BY_YEAR_ERROR,
    payload,
  }),

  getAvailableMwhForEmission: () => ({
    type: Types.GET_AVAILABLE_MWH_FOR_EMISSION,
  }),
  getAvailableMwhForEmissionSuccess: payload => ({
    type: Types.GET_AVAILABLE_MWH_FOR_EMISSION_SUCCESS,
    payload,
  }),
  getAvailableMwhForEmissionError: payload => ({
    type: Types.GET_AVAILABLE_MWH_FOR_EMISSION_ERROR,
    payload,
  }),

  getBusinessBySource: () => ({
    type: Types.GET_BUSINESS_BY_SOURCE,
  }),
  getBusinessBySourceSuccess: payload => ({
    type: Types.GET_BUSINESS_BY_SOURCE_SUCCESS,
    payload,
  }),
  getBusinessBySourceError: payload => ({
    type: Types.GET_BUSINESS_BY_SOURCE_ERROR,
    payload,
  }),

  getLastApprovedRecs: () => ({
    type: Types.GET_LAST_APPROVED_RECS,
  }),
  getLastApprovedRecsSuccess: payload => ({
    type: Types.GET_LAST_APPROVED_RECS_SUCCESS,
    payload,
  }),
  getLastApprovedRecsError: payload => ({
    type: Types.GET_LAST_APPROVED_RECS_ERROR,
    payload,
  }),
};
