import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as ApplicationBiogasActions,
  Types as ApplicationBiogasTypes,
} from 'store/ducks/ApplicationBiogas';
import api from '../../util/Api';

function* getApplicationBiogas({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/applicationBiogas`, {
      params: payload,
    });
    if (status === 200) {
      yield put(ApplicationBiogasActions.getApplicationBiogasListSuccess(data));
    } else {
      yield put(ApplicationBiogasActions.getApplicationBiogasListFail());
    }
  } catch (err) {
    yield put(ApplicationBiogasActions.getApplicationBiogasListFail());
  }
}

function* deleteApplicationBiogas({ payload }) {
  try {
    const { status } = yield call(api.delete, `/applicationBiogas/${payload}`);
    if (status === 200) {
      yield put(ApplicationBiogasActions.deleteApplicationBiogasSuccess());
    } else {
      yield put(ApplicationBiogasActions.deleteApplicationBiogasFail());
    }
  } catch (err) {
    yield put(ApplicationBiogasActions.deleteApplicationBiogasFail());
  }
}

function* createApplicationBiogas({ payload }) {
  try {
    const { status } = yield call(api.post, '/applicationBiogas', payload);
    if (status === 200) {
      yield put(ApplicationBiogasActions.createApplicationBiogasSuccess());
    } else {
      yield put(ApplicationBiogasActions.createApplicationBiogasFail());
    }
  } catch (err) {
    yield put(ApplicationBiogasActions.createApplicationBiogasFail());
  }
}

function* getIdApplicationBiogas({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/applicationBiogas/${payload}`);
    if (status === 200) {
      yield put(ApplicationBiogasActions.getIdApplicationBiogasSuccess(data));
    } else {
      yield put(ApplicationBiogasActions.getIdApplicationBiogasFail());
    }
  } catch (err) {
    yield put(ApplicationBiogasActions.getIdApplicationBiogasFail());
  }
}

function* updateApplicationBiogas({ payload }) {
  try {
    const { status } = yield call(
      api.put,
      `applicationBiogas/${payload.id}`,
      payload.data
    );
    if (status === 200) {
      yield put(ApplicationBiogasActions.updateApplicationBiogasSuccess());
    } else {
      yield put(ApplicationBiogasActions.updateApplicationBiogasFail());
    }
  } catch (err) {
    yield put(ApplicationBiogasActions.updateApplicationBiogasFail());
  }
}

function* getApplicationBiogasWatcher() {
  yield takeLatest(ApplicationBiogasTypes.GET_APPLICATION_BIOGAS_LIST_REQUEST, getApplicationBiogas);
}

function* deleteApplicationBiogasWatcher() {
  yield takeLatest(ApplicationBiogasTypes.DELETE_APPLICATION_BIOGAS_REQUEST, deleteApplicationBiogas);
}

function* createApplicationBiogasWatcher() {
  yield takeLatest(ApplicationBiogasTypes.CREATE_APPLICATION_BIOGAS_REQUEST, createApplicationBiogas);
}

function* getIdApplicationBiogasWatcher() {
  yield takeLatest(ApplicationBiogasTypes.GET_ID_APPLICATION_BIOGAS_REQUEST, getIdApplicationBiogas);
}

function* updateApplicationBiogasWatcher() {
  yield takeLatest(ApplicationBiogasTypes.UPDATE_APPLICATION_BIOGAS_REQUEST, updateApplicationBiogas);
}

export default function* rootSaga() {
  yield all([
    fork(getApplicationBiogasWatcher),
    fork(deleteApplicationBiogasWatcher),
    fork(createApplicationBiogasWatcher),
    fork(getIdApplicationBiogasWatcher),
    fork(updateApplicationBiogasWatcher),
  ]);
}
