export const Types = {
  GET_TECNOLOGY_LIST_REQUEST: 'GET_TECNOLOGY_LIST_REQUEST',
  GET_TECNOLOGY_LIST_SUCCESS: 'GET_TECNOLOGY_LIST_SUCCESS',
  GET_TECNOLOGY_LIST_FAIL: 'GET_TECNOLOGY_LIST_FAIL',

  DELETE_TECHNOLOGY_REQUEST: 'DELETE_TECHNOLOGY_REQUEST',
  DELETE_TECHNOLOGY_SUCCESS: 'DELETE_TECHNOLOGY_SUCCESS',
  DELETE_TECHNOLOGY_FAIL: 'DELETE_TECHNOLOGY_FAIL',
  DELETE_TECHNOLOGY_CLEAN: 'DELETE_TECHNOLOGY_CLEAN',

  CREATE_TECHNOLOGY_REQUEST: 'CREATE_TECHNOLOGY_REQUEST',
  CREATE_TECHNOLOGY_SUCCESS: 'CREATE_TECHNOLOGY_SUCCESS',
  CREATE_TECHNOLOGY_FAIL: 'CREATE_TECHNOLOGY_FAIL',
  CREATE_TECHNOLOGY_CLEAN: 'CREATE_TECHNOLOGY_CLEAN',

  GET_ID_TECHNOLOGY_REQUEST: 'GET_ID_TECHNOLOGY_REQUEST',
  GET_ID_TECHNOLOGY_SUCCESS: 'GET_ID_TECHNOLOGY_SUCCESS',
  GET_ID_TECHNOLOGY_FAIL: 'GET_ID_TECHNOLOGY_FAIL',
  GET_ID_TECHNOLOGY_CLEAN: 'GET_ID_TECHNOLOGY_CLEAN',

  UPDATE_TECHNOLOGY_REQUEST: 'UPDATE_TECHNOLOGY_REQUEST',
  UPDATE_TECHNOLOGY_SUCCESS: 'UPDATE_TECHNOLOGY_SUCCESS',
  UPDATE_TECHNOLOGY_FAIL: 'UPDATE_TECHNOLOGY_FAIL',
  UPDATE_TECHNOLOGY_CLEAN: 'UPDATE_TECHNOLOGY_CLEAN',
};

const INIT_STATE = {
  loading: false,
  error: false,
  data: [],
  amount: 10,

  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,

  idLoading: false,
  idData: null,
  idError: false,

  createLoading: false,
  createSuccess: false,
  createError: false,

  updateLoading: false,
  updateSuccess: false,
  updateError: false,
};

export default function Tecnology(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_TECNOLOGY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case Types.GET_TECNOLOGY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.payload?.data || action?.payload,
        amount: action?.payload?.total,
      };
    case Types.GET_TECNOLOGY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case Types.DELETE_TECHNOLOGY_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      };
    case Types.DELETE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      };
    case Types.DELETE_TECHNOLOGY_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      };
    case Types.DELETE_TECHNOLOGY_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      };

    case Types.CREATE_TECHNOLOGY_REQUEST:
      return {
        ...state,
        createLoading: true,
        createError: false,
        createSuccess: false,
      };
    case Types.CREATE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: true,
      };
    case Types.CREATE_TECHNOLOGY_FAIL:
      return {
        ...state,
        createLoading: false,
        createError: true,
        createSuccess: false,
      };
    case Types.CREATE_TECHNOLOGY_CLEAN:
      return {
        ...state,
        createLoading: false,
        createError: false,
        createSuccess: false,
      };

    case Types.GET_ID_TECHNOLOGY_REQUEST:
      return {
        ...state,
        idLoading: true,
        idError: false,
      };
    case Types.GET_ID_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: action.payload,
      };
    case Types.GET_ID_TECHNOLOGY_FAIL:
      return {
        ...state,
        idLoading: false,
        idError: true,
      };
    case Types.GET_ID_TECHNOLOGY_CLEAN:
      return {
        ...state,
        idLoading: false,
        idError: false,
        idData: false,
      };

    case Types.UPDATE_TECHNOLOGY_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: false,
        updateSuccess: false,
      };
    case Types.UPDATE_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: true,
      };
    case Types.UPDATE_TECHNOLOGY_FAIL:
      return {
        ...state,
        updateLoading: false,
        updateError: true,
        updateSuccess: false,
      };
    case Types.UPDATE_TECHNOLOGY_CLEAN:
      return {
        ...state,
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
      };

    default:
      return state;
  }
}

export const Creators = {
  // GET LIST
  getTecnologyListRequest: payload => ({
    type: Types.GET_TECNOLOGY_LIST_REQUEST,
    payload,
  }),
  getTecnologyListSuccess: payload => ({
    type: Types.GET_TECNOLOGY_LIST_SUCCESS,
    payload,
  }),
  getTecnologyListFail: () => ({
    type: Types.GET_TECNOLOGY_LIST_FAIL,
  }),

  // DELETE
  deleteTechnologyRequest: payload => ({
    type: Types.DELETE_TECHNOLOGY_REQUEST,
    payload,
  }),
  deleteTechnologySuccess: payload => ({
    type: Types.DELETE_TECHNOLOGY_SUCCESS,
    payload,
  }),
  deleteTechnologyFail: () => ({
    type: Types.DELETE_TECHNOLOGY_FAIL,
  }),
  deleteTechnologyClean: () => ({
    type: Types.DELETE_TECHNOLOGY_CLEAN,
  }),

  // CREATE
  createTechnologyRequest: payload => ({
    type: Types.CREATE_TECHNOLOGY_REQUEST,
    payload,
  }),
  createTechnologySuccess: payload => ({
    type: Types.CREATE_TECHNOLOGY_SUCCESS,
    payload,
  }),
  createTechnologyFail: () => ({
    type: Types.CREATE_TECHNOLOGY_FAIL,
  }),
  createTechnologyClean: () => ({
    type: Types.CREATE_TECHNOLOGY_CLEAN,
  }),

  // GET ID
  getIdTechnologyRequest: payload => ({
    type: Types.GET_ID_TECHNOLOGY_REQUEST,
    payload,
  }),
  getIdTechnologySuccess: payload => ({
    type: Types.GET_ID_TECHNOLOGY_SUCCESS,
    payload,
  }),
  getIdTechnologyFail: () => ({
    type: Types.GET_ID_TECHNOLOGY_FAIL,
  }),
  getIdTechnologyClean: () => ({
    type: Types.GET_ID_TECHNOLOGY_CLEAN,
  }),

  // UPDATE
  updateTechnologyRequest: payload => ({
    type: Types.UPDATE_TECHNOLOGY_REQUEST,
    payload,
  }),
  updateTechnologySuccess: payload => ({
    type: Types.UPDATE_TECHNOLOGY_SUCCESS,
    payload,
  }),
  updateTechnologyFail: () => ({
    type: Types.UPDATE_TECHNOLOGY_FAIL,
  }),
  updateTechnologyClean: () => ({
    type: Types.UPDATE_TECHNOLOGY_CLEAN,
  }),
};
