import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import {
  Types as NotaFiscalTypes,
  Creators as NotaFiscalActions,
} from '../ducks/NotaFiscal';
import api from '../../util/Api';

function* getNotas({ payload }) {
  try {
    const { status, data } = yield call(api.get, '/invoices', {
      params: payload,
    });
    if (status === 200) {
      yield put(NotaFiscalActions.getNotasSuccess(data));
    } else {
      yield put(NotaFiscalActions.getNotasError());
    }
  } catch (e) {
    yield put(NotaFiscalActions.getNotasError());
  }
}

function* putNotas({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.put, `/invoices/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (status === 200) {
      yield put(NotaFiscalActions.putNotasSuccess());
    } else {
      yield put(NotaFiscalActions.putNotasError());
    }
  } catch (e) {
    yield put(NotaFiscalActions.putNotasError());
  }
}

function* paidNotas({ payload }) {
  try {
    const { status } = yield call(api.post, `/invoices/toPay/${payload}`);
    if (status === 200) {
      yield put(NotaFiscalActions.paidNFSuccess());
    } else {
      yield put(NotaFiscalActions.paidNFError());
    }
  } catch (e) {
    yield put(NotaFiscalActions.paidNFError());
  }
}

function* getNotaById({ payload }) {
  const { id } = payload;
  try {
    const { status, data } = yield call(api.get, `/invoices/${id}`);
    if (status === 200) {
      yield put(NotaFiscalActions.getNotaByIdSuccess(data));
    } else {
      yield put(NotaFiscalActions.getNotaByIdError());
    }
  } catch {
    yield put(NotaFiscalActions.getNotaByIdError());
  }
}

function* receiptUpload({ payload }) {
  const { id, data } = payload;
  try {
    const { status } = yield call(api.post, `/invoices/receipts/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (status === 200) {
      yield put(NotaFiscalActions.receiptUploadSuccess());
    } else {
      yield put(NotaFiscalActions.receiptUploadError());
    }
  } catch (error) {
    yield put(NotaFiscalActions.receiptUploadError(error.response));
  }
}

function* getNotasWatcher() {
  yield takeLatest(NotaFiscalTypes.GET_NOTAS, getNotas);
}

function* putNotasWatcher() {
  yield takeLatest(NotaFiscalTypes.PUT_NOTAS, putNotas);
}

function* paidNotasWatcher() {
  yield takeLatest(NotaFiscalTypes.PAID_NOTAS, paidNotas);
}

function* getNotaByIdWatcher() {
  yield takeLatest(NotaFiscalTypes.GET_NOTA_BY_ID, getNotaById);
}

function* receiptUploadWatcher() {
  yield takeLatest(NotaFiscalTypes.RECEIPT_UPLOAD, receiptUpload);
}

export default function* rootSaga() {
  yield all([
    fork(getNotasWatcher),
    fork(putNotasWatcher),
    fork(paidNotasWatcher),
    fork(getNotaByIdWatcher),
    fork(receiptUploadWatcher),
  ]);
}
