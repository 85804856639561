import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { AlertTitle } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';

const CustomSnackbars = ({ open, setOpen, text, type, title }) => {
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  function Alert(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={type || 'error'}>
          <AlertTitle>{title || null}</AlertTitle>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomSnackbars;
