export const Types = {
  HIDE_MESSAGE: 'HIDE_MESSAGE',
  INIT_URL: 'INIT_URL',
  ON_HIDE_LOADER: 'ON_HIDE_LOADER',
  ON_SHOW_LOADER: 'ON_SHOW_LOADER',
  SHOW_MESSAGE: 'SHOW_MESSAGE',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOOGIN_ERROR',
  SET_TYPE_USER: 'SET_TYPE_USER',

  CLEAN_AUTH_USER: 'CLEAN_AUTH_USER',
};

const INIT_STATE = {
  alertMessage: null,
  showMessage: false,
  loader: false,
  initURL: '',
  authUser: null,
  authUserError: null,
  authUserLoading: false,
  typeUser: null,
  isLogged: false,
};

export default function Auth(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case Types.SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case Types.HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      };
    }
    case Types.ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case Types.ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case Types.LOGIN: {
      return {
        ...state,
        authUser: null,
        authUserLoading: true,
        authUserError: null,
      };
    }
    case Types.LOGIN_SUCCESS: {
      return {
        ...state,
        authUser: action.payload,
        authUserLoading: false,
        authUserError: null,
      };
    }
    case Types.LOGIN_ERROR: {
      return {
        ...state,
        authUser: null,
        authUserLoading: false,
        authUserError: action.payload,
      };
    }
    case Types.SET_TYPE_USER: {
      return {
        ...state,
        typeUser: action.payload,
      };
    }
    case Types.CLEAN_AUTH_USER: {
      return {
        ...state,
        typeUser: null,
        authUser: null,
      };
    }
    default:
      return state;
  }
}

export const Creators = {
  showAuthMessage: message => ({
    type: Types.SHOW_MESSAGE,
    payload: message,
  }),
  setInitUrl: url => ({
    type: Types.INIT_URL,
    payload: url,
  }),
  showAuthLoader: () => ({
    type: Types.ON_SHOW_LOADER,
  }),
  hideMessage: () => ({
    type: Types.HIDE_MESSAGE,
  }),
  hideAuthLoader: () => ({
    type: Types.ON_HIDE_LOADER,
  }),
  login: payload => ({
    type: Types.LOGIN,
    payload,
  }),
  loginSuccess: payload => ({
    type: Types.LOGIN_SUCCESS,
    payload,
  }),
  loginError: payload => ({
    type: Types.LOGIN_ERROR,
    payload,
  }),
  setTypeUser: payload => ({
    type: Types.SET_TYPE_USER,
    payload,
  }),
  cleanAuthUser: () => ({
    type: Types.CLEAN_AUTH_USER,
  }),
};
