import { all, call, put, takeLatest, fork } from 'redux-saga/effects';
import {
  Creators as SourceActions,
  Types as SourceTypes,
} from 'store/ducks/Source';
import api from '../../util/Api';

function* getSource({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/sources`, {
      params: payload,
    });
    if (status === 200) {
      yield put(SourceActions.getSourceListSuccess(data));
    } else {
      yield put(SourceActions.getSourceListFail());
    }
  } catch (err) {
    yield put(SourceActions.getSourceListFail());
  }
}

function* deleteSource({ payload }) {
  try {
    const { status } = yield call(api.delete, `/sources/${payload}`);
    if (status === 200) {
      yield put(SourceActions.deleteSourceSuccess());
    } else {
      yield put(SourceActions.deleteSourceFail());
    }
  } catch (err) {
    yield put(SourceActions.deleteSourceFail());
  }
}

function* createSource({ payload }) {
  try {
    const { status } = yield call(api.post, '/sources', payload);
    if (status === 200) {
      yield put(SourceActions.createSourceSuccess());
    } else {
      yield put(SourceActions.createSourceFail());
    }
  } catch (err) {
    yield put(SourceActions.createSourceFail());
  }
}

function* getIdSource({ payload }) {
  try {
    const { status, data } = yield call(api.get, `/sources/${payload}`);
    if (status === 200) {
      yield put(SourceActions.getIdSourceSuccess(data));
    } else {
      yield put(SourceActions.getIdSourceFail());
    }
  } catch (err) {
    yield put(SourceActions.getIdSourceFail());
  }
}

function* updateSource({ payload }) {
  try {
    const { status } = yield call(
      api.put,
      `sources/${payload.id}`,
      payload.data
    );
    if (status === 200) {
      yield put(SourceActions.updateSourceSuccess());
    } else {
      yield put(SourceActions.updateSourceFail());
    }
  } catch (err) {
    yield put(SourceActions.updateSourceFail());
  }
}

function* getSourceWatcher() {
  yield takeLatest(SourceTypes.GET_SOURCE_LIST_REQUEST, getSource);
}

function* deleteSourceWatcher() {
  yield takeLatest(SourceTypes.DELETE_SOURCE_REQUEST, deleteSource);
}

function* createSourceWatcher() {
  yield takeLatest(SourceTypes.CREATE_SOURCE_REQUEST, createSource);
}

function* getIdSourceWatcher() {
  yield takeLatest(SourceTypes.GET_ID_SOURCE_REQUEST, getIdSource);
}

function* updateSourceWatcher() {
  yield takeLatest(SourceTypes.UPDATE_SOURCE_REQUEST, updateSource);
}

export default function* rootSaga() {
  yield all([
    fork(getSourceWatcher),
    fork(deleteSourceWatcher),
    fork(createSourceWatcher),
    fork(getIdSourceWatcher),
    fork(updateSourceWatcher),
  ]);
}
