export const Types = {
  GET_USERS: 'GET_USERS',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  POST_USER: 'POST_USER',
  POST_USER_SUCCESS: 'POST_USER_SUCCESS',
  POST_USER_ERROR: 'POST_USER_ERROR',
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  GET_USER_BY_ID_SUCCESS: 'GET_USER_BY_ID_SUCCESS',
  GET_USER_BY_ID_ERROR: 'GET_USER_BY_ID_ERROR',
  PUT_USER: 'PUT_USER',
  PUT_USER_SUCCESS: 'PUT_USER_SUCCESS',
  PUT_USER_ERROR: 'PUT_USER_ERROR',
  USER_CLEAN: 'USER_CLEAN',
};

const INIT_STATE = {
  data: null,
  user: null,
  loading: false,
  error: null,
  postSuccess: false,

  amount: 10,
};

export default function User(state = INIT_STATE, action) {
  switch (action.type) {
    case Types.GET_USERS:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
      };
    case Types.GET_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        amount: action.payload.total,
        loading: false,
        error: null,
      };
    case Types.GET_USERS_ERROR:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case Types.POST_USER:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.POST_USER_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.POST_USER_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.GET_USER_BY_ID:
      return {
        ...state,
        user: false,
        loading: true,
        error: false,
      };
    case Types.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: false,
      };
    case Types.GET_USER_BY_ID_ERROR:
      return {
        ...state,
        user: false,
        loading: false,
        error: action.payload,
      };
    case Types.PUT_USER:
      return {
        ...state,
        postSuccess: false,
        loading: true,
        error: null,
      };
    case Types.PUT_USER_SUCCESS:
      return {
        ...state,
        postSuccess: true,
        loading: false,
        error: null,
      };
    case Types.PUT_USER_ERROR:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: action.payload,
      };
    case Types.USER_CLEAN:
      return {
        ...state,
        postSuccess: false,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  getUsers: payload => ({
    type: Types.GET_USERS,
    payload,
  }),
  getUsersSuccess: payload => ({
    type: Types.GET_USERS_SUCCESS,
    payload,
  }),
  getUsersError: payload => ({
    type: Types.GET_USERS_ERROR,
    payload,
  }),
  postUser: payload => ({
    type: Types.POST_USER,
    payload,
  }),
  postUserSuccess: payload => ({
    type: Types.POST_USER_SUCCESS,
    payload,
  }),
  postUserError: payload => ({
    type: Types.POST_USER_ERROR,
    payload,
  }),
  getUserById: payload => ({
    type: Types.GET_USER_BY_ID,
    payload,
  }),
  getUserByIdSuccess: payload => ({
    type: Types.GET_USER_BY_ID_SUCCESS,
    payload,
  }),
  getUserByIdError: payload => ({
    type: Types.GET_USER_BY_ID_ERROR,
    payload,
  }),
  putUser: payload => ({
    type: Types.PUT_USER,
    payload,
  }),
  putUserSuccess: payload => ({
    type: Types.PUT_USER_SUCCESS,
    payload,
  }),
  putUserError: payload => ({
    type: Types.PUT_USER_ERROR,
    payload,
  }),
  userClean: () => ({
    type: Types.USER_CLEAN,
  }),
};
