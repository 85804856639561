import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Creators as AuthActions } from 'store/ducks/Auth';
import IntlMessages from 'util/IntlMessages';
import { useHistory } from 'react-router-dom';
import { removeTokens, removeTypeUser } from '../../util';
import useUSer from '../../assets/images/useUser.png'

const UserInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authUser } = useSelector(({ auth }) => auth);

  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt="..."
        src={useUSer}
        className="user-avatar "
      />
      <div className="user-detail">
        <h4 className="user-name d-flex" onClick={handleClick}>
          <span className="text-truncate">
            {authUser?.data?.name ||
              authUser?.registrant?.social_name ||
              authUser?.participant?.name ||
              authUser?.issuer?.name ||
              'Usuário'}
          </span>{' '}
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        {/* <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>
        <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.setting" />
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            removeTokens();
            removeTypeUser();
            dispatch(AuthActions.cleanAuthUser());
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;
